<template>
    <div>
        <div v-if="changeInPlan" class="py-5">
            <a-steps class="mb-5" :current="current">
                <a-step
                    v-for="item in steps"
                    :key="item.title"
                    :title="item.title"
                >
                    <span slot="description">{{ item.description }}</span>
                </a-step>
            </a-steps>
            <a-card v-if="current === 1" style="text-align: center">
                <div v-if="!packages.length">
                    <a-icon type="loading" style="font-size: 30px" />
                </div>
                <div v-else>
                    <div class="grid-display mt-8" :style="packageStyle">
                        <div
                            class="type-card"
                            v-for="(p, index) in packages"
                            :key="index"
                            @click="selectedPackageId = p.id"
							style="padding: 30px"
                            :class="{
                                'selected-package': p.id === selectedPackageId,
                            }"
                        >
                            <div
                                class="text-center"
                                style="
                                    color: #000;
                                    font-size: 20px;
                                    margin-top: 10px;
                                    min-height: 60px;
                                "
                            >
                                {{ p.name.toUpperCase() }}
                            </div>
                            <a-divider />
                            <div class="text-center">
                                <p style="font-size: 30px; color: #000">
                                    ${{ p.price }}/mo {{selectedInvoice.instance.currency === 'CAD' ? '(CAD)' : ''}}
                                </p>
                                <div
                                    :style="{
                                        'min-height':
                                            selectedInvoice.instance.projectType ===
                                                'solo' &&
                                            selectedInvoice.instance.productType ===
                                                'lowrise'
                                                ? '160px'
                                                : 'fit-content',
                                    }"
                                >
                                    {{ p.frontCopy }}
                                </div>
                            </div>
                            <a-divider />
                            <div
                                v-if="addOnsPackages.length"
                                style="text-align: left"
                            >
								<strong
                                    style="
                                        font-size: 18px;
                                    "
                                    >Add Ons
                                </strong>
                                <div
                                    v-for="(
                                        addOn, addOnIndex
                                    ) in addOnsPackages"
                                    :key="addOnIndex"
                                >
									<a-checkbox
										v-if="addOn.selected"
										:checked="addOn.selected"
										:disabled="addOn.selected"
										class="py-2"
		                            >
		                                <strong>
											{{ addOn.name }}
		                                    <a-tooltip v-if="addOn.description" overlayClassName="change-tooltip-color" >
		                                        <template slot="title">
			                                            {{addOn.description}}
		                                        </template>
		                                        <a-icon
		                                            type="question-circle"
		                                            style="font-size: 14px"
		                                        />
		                                    </a-tooltip>
										</strong
		                                ><span v-if="addOn.subtitle"> - </span>
		                                {{ addOn.subtitle }}
		                            </a-checkbox>
                                    <a-checkbox
										v-else
										@change="onCheckboxChange($event,addOn,p.id)"
										class="py-2"
                                    >
                                        <strong>{{ addOn.name }}
	                                    <a-tooltip
											v-if="addOn.description"
	                                        overlayClassName="change-tooltip-color"
	                                    >
	                                        <template slot="title">
	                                            {{addOn.description}}
	                                        </template>
	                                        <a-icon
	                                            type="question-circle"
	                                            style="font-size: 14px"
	                                        />
	                                    </a-tooltip>
										</strong><span v-if="addOn.subtitle"> - </span>
                                        {{ addOn.subtitle }}
                                    </a-checkbox>
                                </div>
                            </div>
                            <a-divider />
                            <div style="text-align: left">
                                <div
                                    class="new-package-inputs dF"
                                    style="justify-content: space-between"
                                >
                                    <a-popover placement="right">
                                        <template slot="title">
                                            <h3
                                                class="text-primary text-center"
                                            >
                                                {{
                                                    selectedInvoice.instance.productType ==
                                                    "highrise"
                                                        ? "DOOR FEES"
                                                        : "LOT FEES"
                                                }}
                                            </h3>
                                        </template>
                                        <template slot="content">
                                            <div
                                                style="
                                                    text-align: justify;
                                                    width: 400px;
                                                "
                                            >
                                                Includes 50 lots/doors per
                                                development project. Additional
                                                lots/doors are charged on a per
                                                door basis starting at $30 per
                                                door.
                                            </div>
                                            <div class="modal-list d-flex my-2">
                                                <ul class="p-0">
                                                    <li
                                                        class="modal-list-title"
                                                    >
                                                        Door Fee
                                                    </li>
                                                    <li>First 50</li>
                                                    <li>51 – 100</li>
                                                    <li>101 – 150</li>
                                                    <li>151 – 200</li>
                                                    <li>201+</li>
                                                </ul>
                                                <ul>
                                                    <li
                                                        class="modal-list-title"
                                                    >
                                                        Billed at
                                                    </li>
                                                    <li>$0</li>
                                                    <li>$30</li>
                                                    <li>$25</li>
                                                    <li>$20</li>
                                                    <li>$15</li>
                                                </ul>
                                            </div>
                                            <div
                                                style="
                                                    text-align: justify;
                                                    width: 400px;
                                                "
                                            >
                                                Each project subscription is
                                                inclusive of 50 lots/doors.
                                                Thereafter, a per door charge
                                                applies. The charge is based on
                                                the number of lots/doors to be
                                                processed and supported in your
                                                development project. The Door
                                                fee is a one-time fee that is
                                                over and above your monthly
                                                subscription fee and it is
                                                charged upon initial project set
                                                up.
												Note: Currency rates apply.
                                            </div>
                                        </template>
                                        Additional Number of
                                        {{
                                            selectedInvoice.instance.productType ===
                                            "highrise"
                                                ? "doors"
                                                : "lots"
                                        }}
                                        <a-icon
                                            type="question-circle"
                                            style="
                                                font-size: 14px;
                                                color: black;
                                                margin-left: 5px;
                                            "
                                        />
                                    </a-popover>
                                    <a-input
                                        v-model="lotsOrDoors"
                                        size="small"
                                        type="number"
                                        :min="0"
                                        style="width: 60px"
                                    >
                                    </a-input>
                                </div>
                                <b>{{
                                    selectedInvoice.instance.productType === "highrise"
                                        ? "Doors"
                                        : "Lots"
                                }}</b>
                                - Input the number of
                                {{
                                    selectedInvoice.instance.productType === "highrise"
                                        ? "doors"
                                        : "lots"
                                }}
                                in your development project.
                            </div>

                            <a-divider />
                            <div style="text-align: left">
                                <p
                                    class="new-package-inputs dF"
                                    style="justify-content: space-between"
                                >
                                    <span>
                                        Additional Training Hour Blocks
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                Can be purchased in 4 hour
                                                blocks @ $500/block.
												<br/>
												Note: Currency rates apply.
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="font-size: 14px; margin-left: 5px;"
                                            />
                                        </a-tooltip>
                                    </span>
                                    <a-input
                                        v-model="trainingHourBlocks"
                                        size="small"
                                        type="number"
                                        :min="0"
                                        style="width: 60px"
                                    >
                                    </a-input>
                                </p>
                                <b>Training</b> - Input the training hour blocks.
                            </div>

                            <div class="mt-5 text-center">
                                <div v-if="p.id === selectedInvoice.instance.package">
                                    <h5>CURRENT PLAN</h5>
                                </div>
                                <a-button
                                    v-else
                                    @click="selectedPackageId = p.id"
                                    style="width: 100px; margin-right: 20px"
                                    :class="{
                                        'selected-package':
                                            p.id === selectedPackageId,
                                    }"
                                    >SELECT</a-button
                                >
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <a-button @click="showAllFeatures = !showAllFeatures">
                            <a-icon
                                v-if="!showAllFeatures"
                                type="down"
                                style=""
                            />
                            <a-icon v-if="showAllFeatures" type="up" />
                            SHOW ALL FEATURES
                        </a-button>

                        <div
                            v-if="showAllFeatures"
                            class="grid-display mt-5"
                            :style="packageStyle"
                        >
                            <div
                                class="type-card"
                                v-for="p in packages"
                                :key="p.id"
                                style="
                                    padding: 10px 20px 20px 30px;
                                    text-align: left;
                                "
                            >
                                <AllFeaturesList :package="p.name" :packageCode="p.itemCode" />
                            </div>
                        </div>
                    </div>

                    <div class="dF jE w-full mt-5 mb-5">
                        <a-button-group>
                            <a-button
                                @click="
                                    changeInPlan = false;
                                    current = 1;
                                "
                                style="width: 100px; margin-right: 20px"
                                >BACK</a-button
                            >
                            <a-button
                                :disabled="!selectedPackageId"
                                @click="next"
                                type="primary"
                                style="width: 100px"
                                >NEXT</a-button
                            >
                        </a-button-group>
                    </div>
                </div>
            </a-card>
            <a-card v-else-if="current === 2">
                <div :class="$mq == 'sm' ? '' : 'dF'">
                    <h5 class="text-dark">How would you like to pay?</h5>
                </div>
                <hr style="margin-left: -24px; margin-right: -24px" />
                <div class="profile">
                    <a-row v-if="instancePriceDetails">
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b
                                >Package options
                                <span
                                    class="text-primary ml-5"
                                    @click="current = 1"
                                    style="cursor: pointer"
                                >
                                    Change
                                </span>
                            </b>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b>Price ({{instancePriceDetails.currency || 'USD'}})</b>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                        >
                            <b>Subtotal ({{instancePriceDetails.currency || 'USD'}})</b>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 2 : 1" />
                    </a-row>
                    <hr
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />
                    <a-row v-if="instancePriceDetails && instancePriceDetails.package">
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <div class="text-primary">RECURRING FEES</div>
                            {{ instancePriceDetails.package.name }}
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b>
                                ${{ instancePriceDetails.package.price }}/mth
                            </b>
                            <div>
                                (billed
                                {{ instancePriceDetails.package.recurrence }})
                            </div>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                            class="text-black"
                        >
                            <b>${{ instancePriceDetails.package.price }}</b>
                        </a-col>
                    </a-row>
                    <div v-if="additionalPackages">
                        <a-row
                            v-for="(additional, index) in additionalPackages"
                            :key="index"
                            class="mt-3"
                        >
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <span
                                    v-if="additional.name === 'Application Programming Interface (API Calls)' || additional.name === 'API Calls' || additional.name === 'Online Video Chat Support'"
                                >
                                    <div class="text-primary">
                                        ADD ON FEES
                                    </div>
                                    {{ additional.name }}
                                </span>
                                <span v-else-if="additional.name === 'Onboarding'">
                                    <div class="text-primary">
                                        ONE TIME FEES
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                One time fee (Applicable if you
                                                are a first time user of
                                                Bildhive)
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-tooltip>
                                    </div>
                                    {{ additional.name }}
                                </span>
                                <span v-else-if="additional.name === 'Lot/Door Fee'">
                                    <div class="text-primary">
                                        <a-popover placement="right">
                                            <template slot="title">
                                                <h3
                                                    class="
                                                        text-primary text-center
                                                    "
                                                >
                                                    {{
                                                        selectedInvoice.instance
                                                            .productType ==
                                                        "highrise"
                                                            ? "DOOR FEES"
                                                            : "LOT FEES"
                                                    }}
                                                </h3>
                                            </template>
                                            <template slot="content">
                                                <div
                                                    style="
                                                        text-align: justify;
                                                        width: 400px;
                                                    "
                                                >
                                                    Includes 50 lots/doors per
                                                    development project.
                                                    Additional lots/doors are
                                                    charged on a per door basis
                                                    starting at $30 per door.
                                                </div>
                                                <div
                                                    class="
                                                        modal-list
                                                        d-flex
                                                        my-2
                                                    "
                                                >
                                                    <ul class="p-0">
                                                        <li
                                                            class="
                                                                modal-list-title
                                                            "
                                                        >
                                                            Door Fee
                                                        </li>
                                                        <li>First 50</li>
                                                        <li>51 – 100</li>
                                                        <li>101 – 150</li>
                                                        <li>151 – 200</li>
                                                        <li>201+</li>
                                                    </ul>
                                                    <ul>
                                                        <li
                                                            class="
                                                                modal-list-title
                                                            "
                                                        >
                                                            Billed at
                                                        </li>
                                                        <li>$0</li>
                                                        <li>$30</li>
                                                        <li>$25</li>
                                                        <li>$20</li>
                                                        <li>$15</li>
                                                    </ul>
                                                </div>
                                                <div
                                                    style="
                                                        text-align: justify;
                                                        width: 400px;
                                                    "
                                                >
                                                    Each project subscription is
                                                    inclusive of 50 lots/doors.
                                                    Thereafter, a per door
                                                    charge applies. The charge
                                                    is based on the number of
                                                    lots/doors to be processed
                                                    and supported in your
                                                    development project. The
                                                    Door fee is a one-time fee
                                                    that is over and above your
                                                    monthly subscription fee and
                                                    it is charged upon initial
                                                    project set up.
													Note: Currency rates apply.
                                                </div>
                                            </template>
                                            ADDITIONAL
                                            {{
                                                selectedInvoice.instance.productType ==
                                                "highrise"
                                                    ? "DOOR"
                                                    : "LOT"
                                            }}
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-popover>
                                    </div>
                                    {{
                                        selectedInvoice.instance.productType ==
                                        "highrise"
                                            ? `${lotsOrDoors} doors`
                                            : `${lotsOrDoors} lots`
                                    }}
                                    total
                                </span>
                                <span
                                    v-else-if="additional.name === 'Training Hours'"
                                >
                                    <div class="text-primary">
                                        TRAINING HOUR BLOCKS
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                Additional training hours are
                                                charged in 4 hour blocks @
                                                $500/block.
												<br/>
												Note: Currency rates apply.
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-tooltip>
                                    </div>
                                    {{ additional.quantity }}
                                    Blocks ({{ additional.quantity * 4 }} Hours)
                                </span>
								<span
                                    v-else
                                >
                                    <div class="text-primary tT">
                                        {{ additional.name }}
                                    </div>
                                    {{ additional.quantity }}
                                </span>
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <div
                                    v-if="additional.name === 'Training Hours'"
                                >
                                    <strong>
                                        ${{
                                            +(additional.price /
                                            additional.quantity).toFixed(2)
                                        }}/block(4hours)
                                    </strong>
                                    <div>(billed at time of purchase)</div>
                                </div>
                                <div v-else-if="additional.name === 'Lot/Door Fee'">
                                    <strong>
                                        ${{
                                            +(
                                                additional.price /
                                                parseInt(lotsOrDoors)
                                            ).toFixed(2)
                                        }}
                                        {{
                                            selectedInvoice.instance.productType ==
                                            "highrise"
                                                ? "/door"
                                                : "/lot"
                                        }}
                                    </strong>
                                    <div>(billed at time of purchase)</div>
                                </div>
                                <div v-else>
                                    <strong>
                                        ${{ additional.price && additional.price.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})
                                        }}{{
                                            additional.recurrence === "onetime"
                                                ? ""
                                                : additional.recurrence ===
                                                  "monthly"
                                                ? "/month"
                                                : "/year"
                                        }}
                                    </strong>
                                    <div>
                                        ({{
                                            additional.recurrence === "onetime"
                                                ? "billed at time of set up"
                                                : additional.recurrence ===
                                                  "monthly"
                                                ? "billed monthly"
                                                : "billed yearly"
                                        }})
                                    </div>
                                </div>
                            </a-col>
                            <a-col
                                :span="$mq == 'sm' ? 4 : 6"
                                style="text-align: right"
                                class="text-black"
                            >
                                <b>${{ additional.price && additional.price.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) }}</b>
                            </a-col>
                        </a-row>
                    </div>
                    <hr
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />

                    <a-row v-if="instancePriceDetails">
						<a-col :span="$mq == 'sm' ? 6 : 2" />
                        <a-col
                            :span="$mq == 'sm' ? 6 : 14"
                            style="text-align: right"
                        >
                            <p>Subtotal before tax</p>
                            <p v-if="instancePriceDetails.deductions && instancePriceDetails.deductions.length">Deductions <span>({{ instancePriceDetails.deductions.map(d => d.name).join(", ") }})</span></p>
                            <p v-if="instancePriceDetails.discounts && instancePriceDetails.discounts.length">Discount <span>({{ instancePriceDetails.discounts.map(d => d.name).join(", ") }})</span></p>
                            <p v-if="instancePriceDetails.tax && instancePriceDetails.tax.length">Tax <span>({{ instancePriceDetails.tax.map(t => t.name).join(", ") }})</span></p>
                            <p>Have a coupon code?</p>
                            <p><b>Total Amount</b></p>
                            <p>Currency</p>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                        >
                            <p>${{ instancePriceDetails.subtotal && instancePriceDetails.subtotal.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2) }}</p>
                            <p class="text-red" v-if="instancePriceDetails.deductions && instancePriceDetails.deductions.length">
                                -${{
                                    instancePriceDetails.deductions && instancePriceDetails.deductions
                                        .reduce((acc, deduction) => {
                                            return acc + deduction.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2)
                                }}
                            </p>
                            <p class="text-red" v-if="instancePriceDetails.discounts && instancePriceDetails.discounts.length">
                                -${{
                                    instancePriceDetails.discounts && instancePriceDetails.discounts
                                        .reduce((acc, discount) => {
                                            return acc + discount.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2)
                                }}
                            </p>
                            <p v-if="instancePriceDetails.tax && instancePriceDetails.tax.length">
                                ${{
                                    instancePriceDetails.tax && instancePriceDetails.tax.reduce(
                                        (acc, tax) => {
                                            return acc + tax.price;
                                        },
                                        0
                                    ).toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2)
                                }}
                            </p>

                            <p>
                                <span
                                    class="dF aC ml-3"
                                    style="height: 24px; justify-content: end"
                                >
                                    <a-form-item
                                        style="width: 160px; margin: 0 30px 0 0"
                                        has-feedback
                                        :validate-status="validCouponCode"
                                    >
                                        <a-input
                                            id="couponCode"
                                            v-model="couponCode"
                                            size="small"
                                        />
                                    </a-form-item>
									<a-tooltip v-if="promoCodeDescription" overlayClassName="change-tooltip-color" class="mr-3">
										<template #title>
											{{ promoCodeDescription }}
										</template>
										<a-icon type="question-circle" style="font-size: 14px" />
									</a-tooltip>
                                    <span
                                        class="text-primary"
                                        @click="fetchPriceDetails"
                                        style="cursor: pointer"
                                    >
                                        Apply</span
                                    >
                                </span>
                            </p>
                            <p>
                                <b> ${{ instancePriceDetails.total && instancePriceDetails.total.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2) }} </b>
                            </p>
                            <p>{{ instancePriceDetails.currency || 'USD' }}</p>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 2 : 1" />
                    </a-row>
                </div>
                <hr style="margin-left: -24px; margin-right: -24px" />
				<a-form
                    class="profile mt-5"
                    v-if="existingPaymentMethods.length"
                >
                    <a-row v-if="$mq != 'sm'">
                        <a-col :span="$mq == 'sm' ? 2 : 2" />
                        <a-col :span="$mq == 'sm' ? 8 : 8">
                            <strong> Credit Card Number </strong>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 8 : 8">
                            <strong>Cardholder Name </strong>
                        </a-col>
                        <a-col :span="4">
                            <strong>Expiry Date </strong></a-col
                        >
                    </a-row>
                    <hr
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />
                    <div
                        v-for="card in existingPaymentMethods"
                        :key="card.id"
                    >
                        <a-row>
                            <a-col :span="$mq == 'sm' ? 2 : 1">
                                <a-radio
                                    :value="card.id"
                                    @change="onChange"
                                    :checked="card.id === selectedPaymentMethod"
                                />
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 2 : 2">
                                <img
                                    v-if="
                                        card &&
                                        card.details &&
                                        card.details.ccBrand === 'visa'
                                    "
                                    alt="Visa"
                                    src="@/assets/visa.svg"
                                    width="50px"
                                />
                                <img
                                    v-else-if="
                                        card &&
                                        card.details &&
                                        card.details.ccBrand === 'mc'
                                    "
                                    alt="MasterCard"
                                    src="@/assets/mc.svg"
                                    width="50px"
                                />
                                <img
                                    v-else-if="
                                        card &&
                                        card.details &&
                                        card.details.ccBrand === 'amex'
                                    "
                                    alt="American Express"
                                    src="@/assets/amex.svg"
                                    width="50px"
                                />
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 6 : 6">
                                {{ cardNumberDetails(card.details) }}
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                {{ card.details.cardName }}
                            </a-col>
                            <a-col :span="5">
                                {{ card.details.expiry }}
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 2 : 1" class="dF">
                                <div
                                    class="mr-4 edit-delete"
                                    @click="editCard(card)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-edit-2"
                                    >
                                        <path
                                            d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                                        ></path>
                                    </svg>
                                </div>
                                <div
                                    class="edit-delete"
                                    @click="deleteCard(card)"
                                    style="color: var(--danger)"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-trash-2"
                                    >
                                        <polyline
                                            points="3 6 5 6 21 6"
                                        ></polyline>
                                        <path
                                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                        ></path>
                                        <line
                                            x1="10"
                                            y1="11"
                                            x2="10"
                                            y2="17"
                                        ></line>
                                        <line
                                            x1="14"
                                            y1="11"
                                            x2="14"
                                            y2="17"
                                        ></line>
                                    </svg>
                                </div>
                            </a-col>
                        </a-row>
                        <hr
                            style="margin-left: -24px; margin-right: -24px"
                        />
                    </div>
                </a-form>
				<h5
                    class="pl-3 pt-3"
                    style="color: var(--orange); cursor: pointer"
                    @click="resetPaymentMethodModalData, (paymentMethodModal.show = true)"
                >
                    <a-icon type="plus-circle" /> &nbsp;&nbsp;Add a payment
                    method
                </h5>
                <div :class="$mq == 'sm' ? '' : 'dF'">
                    <div class="dF jE w-full mt-5 mb-5">
                        <a-button-group>
                            <a-button
                                @click="current--"
                                style="width: 100px; margin-right: 20px"
                                >BACK</a-button
                            >
                            <a-button
                                @click="next"
                                :loading="loadModal"
                                type="primary"
                                style="width: 120px"
                                >COMPLETE</a-button
                            >
                        </a-button-group>
                    </div>
                </div>
            </a-card>
        </div>
		<div v-else-if="updateAddons" class="py-5">
            <a-steps class="mb-5" :current="current">
                <a-step
                    v-for="item in addOnSteps"
                    :key="item.title"
                    :title="item.title"
                >
                    <span slot="description">{{ item.description }}</span>
                </a-step>
            </a-steps>
            <a-card v-if="current === 1" style="text-align: center">
                <div v-if="!addOnsPackages.length">
                    <a-icon type="loading" style="font-size: 30px" />
                </div>
                <div v-else style="width: 50%; margin: auto;">
                    <div
                        v-if="addOnsPackages.length"
                        style="text-align: left"
                    >
						<strong
							style=" font-size: 18px; "
                            >Add Ons
                        </strong>
                        <div
                            v-for="(
                                addOn, addOnIndex
                            ) in addOnsPackages"
                            :key="addOnIndex"
                        >
							<a-checkbox
								v-if="addOn.selected"
								:checked="addOn.selected"
								:disabled="addOn.selected"
								class="py-2"
                            >
                                <strong>
									{{ addOn.name }}
                                    <a-tooltip v-if="addOn.description" overlayClassName="change-tooltip-color" >
                                        <template slot="title">
	                                            {{addOn.description}}
                                        </template>
                                        <a-icon
                                            type="question-circle"
                                            style="font-size: 14px"
                                        />
                                    </a-tooltip>
								</strong
                                ><span v-if="addOn.subtitle"> - </span>
                                {{ addOn.subtitle }}
                            </a-checkbox>
							<a-checkbox
								v-else
								@change="onAddOnCheckboxChange($event,addOn)"
								class="py-2"
                            >
                                <strong>
									{{ addOn.name }}
                                    <a-tooltip v-if="addOn.description" overlayClassName="change-tooltip-color" >
                                        <template slot="title">
	                                            {{addOn.description}}
                                        </template>
                                        <a-icon
                                            type="question-circle"
                                            style="font-size: 14px"
                                        />
                                    </a-tooltip>
								</strong
                                ><span v-if="addOn.subtitle"> - </span>
                                {{ addOn.subtitle }}
                            </a-checkbox>
                        </div>
                    </div>
                    <a-divider/>
                    <div style="text-align: left">
                        <div
                            class="new-package-inputs dF"
                            style="justify-content: space-between"
                        >
                            <a-popover placement="right">
                                <template slot="title">
                                    <h3
                                        class="text-primary text-center"
                                    >
                                        {{
                                            instance.productType ==
                                            "highrise"
                                                ? "DOOR FEES"
                                                : "LOT FEES"
                                        }}
                                    </h3>
                                </template>
                                <template slot="content">
                                    <div
                                        style="
                                            text-align: justify;
                                            width: 400px;
                                        "
                                    >
                                        Includes 50 lots/doors per
                                        development project. Additional
                                        lots/doors are charged on a per
                                        door basis starting at $30 per
                                        door.
                                    </div>
                                    <div class="modal-list d-flex my-2">
                                        <ul class="p-0">
                                            <li
                                                class="modal-list-title"
                                            >
                                                Door Fee
                                            </li>
                                            <li>First 50</li>
                                            <li>51 – 100</li>
                                            <li>101 – 150</li>
                                            <li>151 – 200</li>
                                            <li>201+</li>
                                        </ul>
                                        <ul>
                                            <li
                                                class="modal-list-title"
                                            >
                                                Billed at
                                            </li>
                                            <li>$0</li>
                                            <li>$30</li>
                                            <li>$25</li>
                                            <li>$20</li>
                                            <li>$15</li>
                                        </ul>
                                    </div>
                                    <div
                                        style="
                                            text-align: justify;
                                            width: 400px;
                                        "
                                    >
                                        Each project subscription is
                                        inclusive of 50 lots/doors.
                                        Thereafter, a per door charge
                                        applies. The charge is based on
                                        the number of lots/doors to be
                                        processed and supported in your
                                        development project. The Door
                                        fee is a one-time fee that is
                                        over and above your monthly
                                        subscription fee and it is
                                        charged upon initial project set
                                        up.
										Note: Currency rates apply.
                                    </div>
                                </template>
                                Additional Number of
                                {{
                                    selectedInvoice.instance.productType === "highrise"
                                        ? "doors"
                                        : "lots"
                                }}
                                <a-icon
                                    type="question-circle"
                                    style="
                                        font-size: 12px;
                                        color: black;
                                        margin-left: 5px;
                                    "
                                />
                            </a-popover>

                            <a-input
                                v-model="lotsOrDoors"
                                size="small"
                                type="number"
                                :min="0"
                                style="width: 60px"
                            >
                            </a-input>
                        </div>
                        <b>{{
                            selectedInvoice.instance.productType === "highrise"
                                ? "Doors"
                                : "Lots"
                        }}</b>
                        - Input the number of
                        {{
                            selectedInvoice.instance.productType === "highrise"
                                ? "doors"
                                : "lots"
                        }}
                        in your development project.
                    </div>

                    <a-divider/>
                    <div style="text-align: left">
                        <div
                            class="new-package-inputs dF"
                            style="justify-content: space-between"
                        >
                            <span>
                                Additional Training Hour Blocks
                                <a-tooltip overlayClassName="change-tooltip-color">
                                    <template slot="title">
                                        Can be purchased in 4 hour
                                        blocks @ $500/block.
										<br/>
										Note: Currency rates apply.
                                    </template>
                                    <a-icon
                                        type="question-circle"
                                        style="font-size: 12px"
                                    />
                                </a-tooltip>
                            </span>
                            <a-input
                                v-model="trainingHourBlocks"
                                size="small"
                                type="number"
                                :min="0"
                                style="width: 60px"
                            >
                            </a-input>
                        </div>
                        <b>Training</b> - Input the training hour blocks.
                    </div>

                    <div class="dF jE w-full mt-5 mb-5">
                        <a-button-group>
                            <a-button
                                @click="updateAddons = false;current = 1; couponCode = ''"
                                style="width: 100px; margin-right: 20px"
                                >BACK</a-button
                            >
                            <a-button
                                :disabled="addOnNextButtonDisabled"
                                @click="fetchAddOnPriceDetails"
                                type="primary"
                                style="width: 100px"
                                >NEXT</a-button
                            >
                        </a-button-group>
                    </div>
                </div>
            </a-card>
            <a-card v-else-if="current === 2">
                <div :class="$mq == 'sm' ? '' : 'dF'">
                    <h5 class="text-dark">How would you like to pay?</h5>
                </div>
                <hr style="margin-left: -24px; margin-right: -24px" />
                <div class="profile">
                    <a-row v-if="instancePriceDetails">
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b
                                >Package options
                                <span
                                    class="text-primary ml-5"
                                    @click="current = 1"
                                    style="cursor: pointer"
                                >
                                    Change
                                </span>
                            </b>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b
                                >Price ({{
                                    instancePriceDetails.currency || "USD"
                                }})</b
                            >
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                        >
                            <b
                                >Subtotal ({{
                                    instancePriceDetails.currency || "USD"
                                }})</b
                            >
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 2 : 1" />
                    </a-row>
                    <hr style="margin-left: -24px; margin-right: -24px"/>
                    <a-row v-if="instancePriceDetails && instancePriceDetails.package && instancePriceDetails.package">
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <div class="text-primary">RECURRING FEES</div>
                            {{ instancePriceDetails.package && instancePriceDetails.package.name }}
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b>
                                ${{ instancePriceDetails.package && instancePriceDetails.package.price }}/mth
                            </b>
                            <div>
                                (billed
                                {{ instancePriceDetails.package && instancePriceDetails.package.recurrence }})
                            </div>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                            class="text-black"
                        >
                            <b>${{ instancePriceDetails.package && instancePriceDetails.package.price }}</b>
                        </a-col>
                    </a-row>
                    <div>
                        <a-row
                            v-for="(additional, index) in additionalPackages"
                            :key="index"
                            class="mt-3"
                        >
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <span
                                    v-if="additional.name === 'Application Programming Interface (API Calls)' || additional.name === 'API Calls' || additional.name === 'Online Video Chat Support'"
                                >
                                    <div class="text-primary">
                                        ADD ON FEES
                                    </div>
                                    {{ additional.name }}
                                </span>
                                <span v-else-if="additional.name === 'Onboarding'">
                                    <div class="text-primary">
                                        ONE TIME FEES
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                One time fee (Applicable if you
                                                are a first time user of
                                                Bildhive)
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-tooltip>
                                    </div>
                                    {{ additional.name }}
                                </span>
                                <span v-else-if="additional.name === 'Lot/Door Fee'">
                                    <div class="text-primary">
                                        <a-popover placement="right">
                                            <template slot="title">
                                                <h3
                                                    class="
                                                        text-primary text-center
                                                    "
                                                >
                                                    {{
                                                        instance.productType ==
                                                        "highrise"
                                                            ? "DOOR FEES"
                                                            : "LOT FEES"
                                                    }}
                                                </h3>
                                            </template>
                                            <template slot="content">
                                                <div
                                                    style="
                                                        text-align: justify;
                                                        width: 400px;
                                                    "
                                                >
                                                    Includes 50 lots/doors per
                                                    development project.
                                                    Additional lots/doors are
                                                    charged on a per door basis
                                                    starting at $30 per door.
                                                </div>
                                                <div
                                                    class="
                                                        modal-list
                                                        d-flex
                                                        my-2
                                                    "
                                                >
                                                    <ul class="p-0">
                                                        <li
                                                            class="
                                                                modal-list-title
                                                            "
                                                        >
                                                            Door Fee
                                                        </li>
                                                        <li>First 50</li>
                                                        <li>51 – 100</li>
                                                        <li>101 – 150</li>
                                                        <li>151 – 200</li>
                                                        <li>201+</li>
                                                    </ul>
                                                    <ul>
                                                        <li
                                                            class="
                                                                modal-list-title
                                                            "
                                                        >
                                                            Billed at
                                                        </li>
                                                        <li>$0</li>
                                                        <li>$30</li>
                                                        <li>$25</li>
                                                        <li>$20</li>
                                                        <li>$15</li>
                                                    </ul>
                                                </div>
                                                <div
                                                    style="
                                                        text-align: justify;
                                                        width: 400px;
                                                    "
                                                >
                                                    Each project subscription is
                                                    inclusive of 50 lots/doors.
                                                    Thereafter, a per door
                                                    charge applies. The charge
                                                    is based on the number of
                                                    lots/doors to be processed
                                                    and supported in your
                                                    development project. The
                                                    Door fee is a one-time fee
                                                    that is over and above your
                                                    monthly subscription fee and
                                                    it is charged upon initial
                                                    project set up.
													Note: Currency rates apply.
                                                </div>
                                            </template>
                                            ADDITIONAL
                                            {{
                                                selectedInvoice.instance.productType ==
                                                "highrise"
                                                    ? "DOOR"
                                                    : "LOT"
                                            }}
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-popover>
                                    </div>
                                    {{
                                        selectedInvoice.instance.productType == "highrise"
                                            ? `${lotsOrDoors} doors`
                                            : `${lotsOrDoors} lots`
                                    }}
                                    total
                                </span>
                                <span
                                    v-else-if="additional.name === 'Training Hours'"
                                >
                                    <div class="text-primary">
                                        TRAINING HOUR BLOCKS
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                Additional training hours are
                                                charged in 4 hour blocks @
                                                $500/block.
												<br/>
												Note: Currency rates apply.
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-tooltip>
                                    </div>
                                    {{ additional.quantity }}
                                    Blocks ({{ additional.quantity * 4 }} Hours)
                                </span>
								<span
                                    v-else
                                >
                                    <div class="text-primary tT">
                                        {{ additional.name }}
                                    </div>
                                    {{ additional.quantity }}
                                </span>
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <div
                                    v-if="additional.name === 'Training Hours'"
                                >
                                    <strong>
                                        ${{
                                            +(additional.price /
                                            additional.quantity).toFixed(2)
                                        }}/block(4hours)
                                    </strong>
                                    <div>(billed at time of purchase)</div>
                                </div>
                                <div v-else-if=" additional.name === 'Lot/Door Fee'">
                                    <strong>
                                        ${{
                                            +(
                                                additional.price /
                                                parseInt(lotsOrDoors)
                                            ).toFixed(2)
                                        }}
                                        {{
                                            selectedInvoice.instance.productType == "highrise"
                                                ? "/door"
                                                : "/lot"
                                        }}
                                    </strong>
                                    <div>(billed at time of purchase)</div>
                                </div>
                                <div v-else>
                                    <strong>
                                        ${{
                                            additional.price &&
                                            additional.price.toLocaleString(
                                                "en-US",
                                                {
                                                    useGrouping: true,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            )
                                        }}{{
                                            additional.recurrence === "onetime"
                                                ? ""
                                                : additional.recurrence ===
                                                  "monthly"
                                                ? "/month"
                                                : "/year"
                                        }}
                                    </strong>
                                    <div>
                                        ({{
                                            additional.recurrence === "onetime"
                                                ? "billed at time of set up"
                                                : additional.recurrence ===
                                                  "monthly"
                                                ? "billed monthly"
                                                : "billed yearly"
                                        }})
                                    </div>
                                </div>
                            </a-col>
                            <a-col
                                :span="$mq == 'sm' ? 4 : 6"
                                style="text-align: right"
                                class="text-black"
                            >
                                <b
                                    >${{
                                        additional.price &&
                                        additional.price.toLocaleString(
                                            "en-US",
                                            {
                                                useGrouping: true,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        )
                                    }}</b
                                >
                            </a-col>
                        </a-row>
                    </div>
                    <hr style="margin-left: -24px; margin-right: -24px"/>
                    <a-row v-if="instancePriceDetails">
						<a-col :span="$mq == 'sm' ? 6 : 2" />
                        <a-col
                            :span="$mq == 'sm' ? 6 : 14"
                            style="text-align: right"
                        >
                            <p>Subtotal before tax</p>
                            <p v-if="instancePriceDetails.deductions && instancePriceDetails.deductions.length">Deductions <span>({{ instancePriceDetails.deductions.map(d => d.name).join(", ") }})</span></p>
                            <p v-if="instancePriceDetails.discounts && instancePriceDetails.discounts.length">Discount <span>({{ instancePriceDetails.discounts.map(d => d.name).join(", ") }})</span></p>
                            <p v-if="instancePriceDetails.tax && instancePriceDetails.tax.length">Tax <span>({{ instancePriceDetails.tax.map(t => t.name).join(", ") }})</span></p>
                            <p>Have a coupon code?</p>
                            <p><b>Total Amount</b></p>
                            <p>Currency</p>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                        >
                            <p>
                               ${{
                                    instancePriceDetails.subtotal &&
                                    instancePriceDetails.subtotal.toLocaleString(
                                        "en-US",
                                        {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )
                                }}
                            </p>
                            <p class="text-red" v-if="instancePriceDetails.deductions && instancePriceDetails.deductions.length">
                                -${{
                                    instancePriceDetails.deductions && instancePriceDetails.deductions
                                        .reduce((acc, deduction) => {
                                            return acc + deduction.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) || (0).toFixed(2)
                                }}
                            </p>
                            <p class="text-red" v-if="instancePriceDetails.discounts && instancePriceDetails.discounts.length">
                                -${{
									instancePriceDetails.discounts &&
                                    instancePriceDetails.discounts
                                        .reduce((acc, discount) => {
                                            return acc + discount.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) || (0).toFixed(2)
                                }}
                            </p>
                            <p v-if="instancePriceDetails.tax && instancePriceDetails.tax.length">
                                ${{
									instancePriceDetails.tax &&
                                    instancePriceDetails.tax
                                        .reduce((acc, tax) => {
                                            return acc + tax.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) || (0).toFixed(2)
                                }}
                            </p>

                            <p>
                                <span
                                    class="dF aC ml-3"
                                    style="height: 24px; justify-content: end"
                                >
                                    <a-form-item
                                        style="width: 160px; margin: 0 30px 0 0"
                                        has-feedback
                                        :validate-status="validCouponCode"
                                    >
                                        <a-input
                                            id="couponCode"
                                            v-model="couponCode"
                                            size="small"
                                        />
                                    </a-form-item>
									<a-tooltip v-if="promoCodeDescription" overlayClassName="change-tooltip-color" class="mr-3">
										<template #title>
											{{ promoCodeDescription }}
										</template>
										<a-icon type="question-circle" style="font-size: 14px" />
									</a-tooltip>
                                    <span
                                        class="text-primary"
                                        @click="fetchAddOnPriceDetails"
                                        style="cursor: pointer"
                                    >
                                        Apply</span
                                    >
                                </span>
                            </p>
                            <p>
                                <b>
                                    ${{
                                        instancePriceDetails.total &&
                                        instancePriceDetails.total.toLocaleString(
                                            "en-US",
                                            {
                                                useGrouping: true,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        )
                                    }}
                                </b>
                            </p>
                            <p>{{ instancePriceDetails.currency || "USD" }}</p>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 2 : 1" />
                    </a-row>
                </div>
                <hr style="margin-left: -24px; margin-right: -24px" />
                <div :class="$mq == 'sm' ? '' : 'dF'">
                    <div class="dF jE w-full m-3">
                        <a-button-group>
                            <a-button
                                @click="current--; couponCode = ''"
                                style="width: 100px; margin-right: 20px"
                                >BACK</a-button
                            >
                            <a-button
                                @click="addAddOns"
                                :loading="loadModal"
                                type="primary"
                                style="width: 120px"
                                >COMPLETE</a-button
                            >
                        </a-button-group>
                    </div>
                </div>
            </a-card>
        </div>
        <div
            v-else-if="!Object.keys(selectedInvoice).length"
            style="text-align: center"
        >
            <a-icon type="loading" style="font-size: 30px" />
        </div>
        <div v-else class="mb-5">
            <div class="dF aC" style="justify-content: space-between">
                <div class="py-2" style="font-size: 18px">
                    <a-icon
                        type="arrow-left"
                        class="mr-3"
                        @click="$emit('back')"
                    />
                    <strong>{{
                        selectedInvoice.instance && selectedInvoice.instance.name
                    }}</strong>
                </div>
                <div class="dF pull-right" style="gap: 20px">
                    <div
                        @click="previewModal = false; invoiceToDownload = selectedInvoice"
                        class="dF aC"
                        style="color: var(--orange); cursor: pointer"
                    >
                        <a-icon
                            class="mr-1"
                            type="download"
                            style="font-size: 24px"
                        />
                        PDF
                    </div>
                    <div
                        @click="previewModal = true; invoiceToDownload = selectedInvoice"
                        class="dF aC"
                        style="color: var(--orange); cursor: pointer"
                    >
                        <a-icon
                            class="mr-1"
                            type="eye"
                            style="font-size: 24px"
                        />
                        PDF
                    </div>
                </div>
            </div>
            <div class="mt-3 mb-3">
                <div class="card-grid">
                    <a-card>
						<a-row :gutter="16">
							<a-col :span="8">
                                <h5>
                                    Invoice #{{
                                        selectedInvoice.invoiceNumber
                                    }}
                                </h5>
                            </a-col>
							<a-col :span="8">
	                            <div
	                                class="py-1 px-3 text-center"
	                                :style="
	                                    selectedInvoice.paid
	                                        ? 'background-color:#28C791'
	                                        : 'background-color:#FD647C'
	                                "
	                                style="
	                                    color: #fff;
	                                    border-radius: 4px;
	                                    width: fit-content;
	                                "
	                            >
									{{ selectedInvoice.paid ? "PAID" : "UNPAID" }}
	                            </div>
	                        </a-col>
							<a-col :span="8" v-if="selectedInvoice.paymentMode !== 'offline'">
								<a-button @click="makePayment(selectedInvoice.id)" type="primary" :disabled="selectedInvoice.paid">
									MAKE A PAYMENT
								</a-button>
	                        </a-col>
						</a-row>
						<a-row :gutter="16" class="mt-2">
                            <a-col :span="16">
                                <div class="aC">
                                    <h4>
                                        ${{
                                            selectedInvoice.total
                                        }}
                                        ({{
                                            selectedInvoice.currency ||
                                            "USD"
                                        }})
                                    </h4>
                                </div>
                            </a-col>
                        </a-row>
						<span
                            class="w-full dF pl-2"
                            style="background-color: lightpink"
                            v-if="!selectedInvoice.paid && selectedInvoice.paymentMode !== 'offline'"
                            >{{
                                selectedInvoice.error || "Your card has been declined"
                            }}</span
                        >
						<a-row :gutter="16" class="mt-2">
                            <a-col :span="8">
                                <h5>Billing Period</h5>
                                <span style="color: #9ea0a5">{{ !selectedInvoice.validUntil ? "N/A" :
                                    `${getDate(
                                        selectedInvoice.createdAt
                                    )} - ${getDate(
                                        +selectedInvoice.validUntil
                                    )}`
                                }}</span>
                            </a-col>
                            <a-col :span="8">
                                <h5>Next Invoice Date</h5>
                                <span style="color: #9ea0a5">{{
									!selectedInvoice.validUntil ? "N/A" : getDate(+selectedInvoice.validUntil)
                                }}</span>
                            </a-col>
                            <a-col
                                :span="8"
                                v-if="
                                    selectedInvoice.refundStatus ===
                                    'partial' && selectedInvoice.refunds
                                "
                            >
                                <h5>Refund Amount</h5>
                                <span style="color: #9ea0a5"
                                    >{{
                                        calculateTotalRefund(
                                            selectedInvoice.refunds
                                        )
                                    }}
                                    ({{
                                        selectedInvoice.currency ||
                                        "USD"
                                    }})</span
                                >
                            </a-col>
                            <a-col
                                :span="8"
                                v-if="
                                    selectedInvoice.refundStatus ===
                                    'full'
                                "
                            >
                                <h5>Refund Amount</h5>
                                <span style="color: #9ea0a5"
                                    >${{
                                        selectedInvoice.total
                                    }}
                                    ({{
                                        selectedInvoice.currency ||
                                        "USD"
                                    }})</span
                                >
                            </a-col>
                        </a-row>
                    </a-card>
                    <a-card size="small">
                        <h6>Current Plan</h6>
                        <h4>{{ selectedInvoice.details && selectedInvoice.details.package && selectedInvoice.details.package.name }}</h4>
                        <div class="dF aC jSB">
							<div
	                            @click="changePlan"
	                            class="py-1 px-2 mt-5 text-center gray-button"
	                            :style="
	                                !instanceNotFound
	                                    ? ''
	                                    : 'background-color:var(--light-gray); color:var(--med-gray); cursor:not-allowed'
	                            "
	                        >
	                            CHANGE OR UPDATE PLAN
	                        </div>
							<div
                                @click="addAddons"
                                class="py-1 px-2 mt-5 text-center gray-button"
								:style="
	                                !instanceNotFound
	                                    ? ''
	                                    : 'background-color:var(--light-gray); color:var(--med-gray); cursor:not-allowed'
	                            "
                            >
                                ADD ADDONS
                            </div>
						</div>
                    </a-card>
                    <a-card size="small" v-if="selectedInvoice.paymentMode !== 'offline'">
                        <h6>Will be automatically charged to:</h6>
                        <h4>
                            <img
                                v-if="
                                    currentPaymentMethod &&
                                    currentPaymentMethod.details &&
                                    currentPaymentMethod.details.ccBrand === 'visa'
                                "
                                alt="Visa"
                                src="@/assets/visa.svg"
                                width="12%"
                                height="12%"
                            />
                            <img
                                v-else-if="
                                    currentPaymentMethod &&
                                    currentPaymentMethod.details &&
                                    currentPaymentMethod.details.ccBrand === 'mc'
                                "
                                alt="MasterCard"
                                src="@/assets/mc.svg"
                                width="12%"
                                height="12%"
                            />
                            <img
                                v-else-if="
                                    currentPaymentMethod &&
                                    currentPaymentMethod.details &&
                                    currentPaymentMethod.details.ccBrand === 'amex'
                                "
                                alt="American Express"
                                src="@/assets/amex.svg"
                                width="12%"
                                height="12%"
                            />
                            ending {{ currentPaymentMethod &&
                                    currentPaymentMethod.details && currentPaymentMethod.details.cardNo && currentPaymentMethod.details.cardNo.substr(-4) }}
                        </h4>
                        <div
                            @click="switchCardModal.visible = true; switchCardModal.card = currentPaymentMethod;"
                            class="py-1 px-2 mt-5 text-center gray-button"
                        >
                            CHANGE PAYMENT METHOD
                        </div>
                    </a-card>
                </div>
            </div>

            <div class="profile mt-5">
                <a-card>
                    <h4>Summary</h4>
                    <hr
                        class="mt-3"
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />
                    <a-row>
                        <a-col :span="$mq == 'sm' ? 12 : 16">
                            <b>Package options </b>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                        >
                            <b>Subtotal</b>
                        </a-col>
                    </a-row>
                    <hr
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />
                    <a-row v-if="selectedInvoice.details && selectedInvoice.details.package">
                        <a-col :span="$mq == 'sm' ? 12 : 16">
                            <div class="text-primary">RECURRING FEES</div>
                            {{ selectedInvoice.details.package.name }}
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                            class="text-black"
                        >
                            <b
                                >${{
                                    selectedInvoice.details.package.price
                                }}</b
                            >
                        </a-col>
                    </a-row>
                    <hr
                        v-if="$mq != 'sm' && selectedInvoice.details && selectedInvoice.details.package"
                        style="margin-left: -24px; margin-right: -24px"
                    />
                    <div
                        v-for="(
                            additional, index
                        ) in (selectedInvoice.details && selectedInvoice.details.selectedPackageExtras || [])"
                        :key="index"
                    >
                        <a-row>
                            <a-col :span="$mq == 'sm' ? 12 : 16">
                                <span
                                    v-if="additional.name === 'Application Programming Interface (API Calls)' || additional.name === 'API Calls' || additional.name === 'Online Video Chat Support'"
                                >
                                    <div class="text-primary">
                                        ADD ON FEES
                                    </div>
                                    {{ additional.name }}
                                </span>
                                <span
                                    v-else-if="additional.name === 'Onboarding'"
                                >
                                    <div class="text-primary">
                                        ONE TIME FEES
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                One time fee (Applicable if you
                                                are a first time user of
                                                Bildhive)
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-tooltip>
                                    </div>
                                    {{ additional.name }}
                                </span>
                                <span
                                    v-else-if="
                                        additional.name === 'Lot/Door Fee'
                                    "
                                >
                                    <div class="text-primary">
                                        <a-popover placement="right">
                                            <template slot="title">
                                                <h3
                                                    class="
                                                        text-primary text-center
                                                    "
                                                >
                                                    {{
                                                        selectedInvoice.instance.productType ==
                                                        "highrise"
                                                            ? "DOOR FEES"
                                                            : "LOT FEES"
                                                    }}
                                                </h3>
                                            </template>
                                            <template slot="content">
                                                <div
                                                    style="
                                                        text-align: justify;
                                                        width: 400px;
                                                    "
                                                >
                                                    Includes 50 lots/doors per
                                                    development project.
                                                    Additional lots/doors are
                                                    charged on a per door basis
                                                    starting at $30 per door.
                                                </div>
                                                <div
                                                    class="
                                                        modal-list
                                                        d-flex
                                                        my-2
                                                    "
                                                >
                                                    <ul class="p-0">
                                                        <li
                                                            class="
                                                                modal-list-title
                                                            "
                                                        >
                                                            Door Fee
                                                        </li>
                                                        <li>First 50</li>
                                                        <li>51 – 100</li>
                                                        <li>101 – 150</li>
                                                        <li>151 – 200</li>
                                                        <li>201+</li>
                                                    </ul>
                                                    <ul>
                                                        <li
                                                            class="
                                                                modal-list-title
                                                            "
                                                        >
                                                            Billed at
                                                        </li>
                                                        <li>$0</li>
                                                        <li>$30</li>
                                                        <li>$25</li>
                                                        <li>$20</li>
                                                        <li>$15</li>
                                                    </ul>
                                                </div>
                                                <div
                                                    style="
                                                        text-align: justify;
                                                        width: 400px;
                                                    "
                                                >
                                                    Each project subscription is
                                                    inclusive of 50 lots/doors.
                                                    Thereafter, a per door
                                                    charge applies. The charge
                                                    is based on the number of
                                                    lots/doors to be processed
                                                    and supported in your
                                                    development project. The
                                                    Door fee is a one-time fee
                                                    that is over and above your
                                                    monthly subscription fee and
                                                    it is charged upon initial
                                                    project set up.
													Note: Currency rates apply.
                                                </div>
                                            </template>
                                            {{
                                                selectedInvoice.instance && selectedInvoice.instance.productType ==
                                                "highrise"
                                                    ? "DOOR"
                                                    : "LOT"
                                            }}
                                            FEES
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-popover>
                                    </div>
                                    {{
                                        selectedInvoice.instance && selectedInvoice.instance.productType ==
                                        "highrise"
                                            ? `${additional.quantity} doors`
                                            : `${additional.quantity} lots`
                                    }}
                                    total
                                </span>
                                <span
                                    v-else-if="
                                        additional.name === 'Training Hours'
                                    "
                                >
                                    <div class="text-primary">
                                        TRAINING HOUR BLOCKS
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                Additional training hours are
                                                charged in 4 hour blocks @
                                                $500/block.
												<br/>
												Note: Currency rates apply.
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-tooltip>
                                    </div>
                                    {{ additional.quantity }}
                                    Blocks ({{ additional.quantity * 4 }} Hours)
                                </span>
								<span
                                    v-else-if="
                                        additional.name === 'Email sends'
                                    "
                                >
                                    <div class="text-primary tT">
                                        {{ additional.name }}
                                    </div>
                                    {{ additional.quantity }} Emails
                                </span>
								<span
                                    v-else
                                >
                                    <div class="text-primary tT">
                                        {{ additional.name }}
                                    </div>
                                    {{ additional.quantity }}
                                </span>
                            </a-col>
                            <a-col
                                :span="$mq == 'sm' ? 4 : 6"
                                style="text-align: right"
                                class="text-black"
                            >
                                <b>${{ additional.price && additional.price.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || (0).toFixed(2) }}</b>
                            </a-col>
                        </a-row>
                        <hr
                            v-if="$mq != 'sm'"
                            style="margin-left: -24px; margin-right: -24px"
                        />
                    </div>
					<div
						v-if="selectedInvoice.details && selectedInvoice.details.usageDetails"
                    >
						<a-row>
                            <a-col :span="$mq == 'sm' ? 12 : 16">
                                <span>
                                    <div class="text-primary">
                                        USAGE FEES
                                    </div>
                                    {{ selectedInvoice.details.usageDetails.name }}
                                </span>
							</a-col>
							<a-col
                                :span="$mq == 'sm' ? 4 : 6"
                                style="text-align: right"
                                class="text-black"
                            >
                                <b>${{ selectedInvoice.details.usageDetails.price && selectedInvoice.details.usageDetails.price.toLocaleString("en-US", {
										useGrouping: false,
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}) || (0).toFixed(2) }}</b>
                            </a-col>
						</a-row>
						<hr
                            v-if="$mq != 'sm'"
                            style="margin-left: -24px; margin-right: -24px"
                        />
					</div>
                    <a-row>
						<a-col :span="$mq == 'sm' ? 6 : 2" />
                        <a-col
                            :span="$mq == 'sm' ? 6 : 14"
                            style="text-align: right"
                        >
                            <p>Subtotal before tax</p>
                            <p v-if="selectedInvoice.details && selectedInvoice.details.deductions && selectedInvoice.details.deductions.length">Deductions <span>({{ selectedInvoice.details.deductions.map(d => d.name).join(", ") }})</span></p>
                            <p v-if="selectedInvoice.details && selectedInvoice.details.appliedDiscounts && selectedInvoice.details.appliedDiscounts.length">Discount <span>({{ selectedInvoice.details.appliedDiscounts.map(d => d.name).join(", ") }})</span></p>
                            <p v-if="selectedInvoice.details && selectedInvoice.details.taxes && selectedInvoice.details.taxes.length">Tax <span>({{ selectedInvoice.details.taxes.map(t => t.name).join(", ") }})</span></p>
                            <p v-if="selectedInvoice.promoCode">Promo Code</p>
							<p><b>Total Amount</b></p>
                            <p>Currency</p>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                        >
                            <p>${{ selectedInvoice.subTotal }}</p>
							<p class="text-red" v-if="selectedInvoice.details && selectedInvoice.details.deductions && selectedInvoice.details.deductions.length">
                                -${{
                                    selectedInvoice.details && selectedInvoice.details.deductions && selectedInvoice.details.deductions
                                        .reduce((acc, discount) => {
                                            return acc + discount.price;
                                        }, 0)
                                        .toFixed(2) || (0).toFixed(2)
                                }}
                            </p>
                            <p class="text-red" v-if="selectedInvoice.details && selectedInvoice.details.appliedDiscounts && selectedInvoice.details.appliedDiscounts.length">
                                -${{
                                    selectedInvoice.details && selectedInvoice.details.appliedDiscounts && selectedInvoice.details.appliedDiscounts
                                        .reduce((acc, discount) => {
                                            return acc + discount.price;
                                        }, 0)
                                        .toFixed(2) || (0).toFixed(2)
                                }}
                            </p>
                            <p v-if="selectedInvoice.details && selectedInvoice.details.taxes && selectedInvoice.details.taxes.length">
                                ${{
                                    selectedInvoice.details && selectedInvoice.details.taxes && selectedInvoice.details.taxes
									.reduce(
                                        (acc, tax) => {
                                            return acc + tax.price;
                                        },
                                        0
                                    ).toFixed(2) || (0).toFixed(2)
                                }}
                            </p>
							<p>
								<a-tag v-if="selectedInvoice.promoCode" class="m-0">{{selectedInvoice.promoCode}}</a-tag>
								<a-tooltip v-if="getPromoCodeDescription(selectedInvoice.promoCode)" overlayClassName="change-tooltip-color" class="ml-1">
									<template #title>
										{{ getPromoCodeDescription(selectedInvoice.promoCode) }}
									</template>
									<a-icon type="question-circle" style="font-size: 14px" />
								</a-tooltip>
							</p>
                            <p>
                                <b>
                                    ${{
                                        selectedInvoice.total &&
                                        selectedInvoice.total.toLocaleString(
                                            "en-US",
                                            {
                                                useGrouping: true,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        ) || (0).toFixed(2)
                                    }}
                                </b>
                            </p>
                            <p>{{ selectedInvoice.currency || 'USD' }}</p>
                        </a-col>
                    </a-row>
                </a-card>
            </div>

			<div class="profile mt-5" v-if="selectedInvoice.payments && selectedInvoice.payments.length">
				<a-card>
                    <h4>Invoice Offline Payments</h4>
					<hr
                        class="mt-3"
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />
					<a-table
                        :rowKey="(e, i) => e.id"
                        :columns="paymentsColumns"
                        :dataSource="selectedInvoice.payments"
                        class="list-view"
						:loading="loading"
                    >
                        <div slot="amount" slot-scope="obj">
                            ${{ obj.amount && obj.amount.toLocaleString("en-US", {
										useGrouping: false,
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}) || 0 }} ({{ selectedInvoice.currency || "USD"}})
                        </div>
                        <div slot="paymentDate" slot-scope="obj">
                            {{
                                getDateTime(obj.paymentDate)
                            }}
                        </div>
						<div slot="paymentMode" slot-scope="obj">
                            {{ obj.paymentMode === 'check' ? 'Check' :  obj.paymentMode === 'bank_transfer' ? 'Bank Transfer' : 'Cash'  }}
                        </div>
						<div slot="note" slot-scope="obj">
                            {{ obj.note  }}
                        </div>
                    </a-table>
				</a-card>
			</div>
			<div class="profile mt-5" v-if="selectedInvoice.refunds && selectedInvoice.refunds.length">
				<a-card>
                    <h4>Invoice Refunds</h4>
					<hr
                        class="mt-3"
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />
					<a-table
                        :rowKey="(e, i) => e.id"
                        :columns="refundColumns"
                        :dataSource="selectedInvoice.refunds"
                        class="list-view"
						:loading="loading"
                    >
                        <div slot="amount" slot-scope="obj">
                            ${{ obj.amount && obj.amount.toLocaleString("en-US", {
										useGrouping: false,
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}) || 0 }} ({{ selectedInvoice.currency || "USD"}})
                        </div>
						<div slot="reason" slot-scope="obj">
                            {{ obj.reason  }}
                        </div>
						<div slot="createdAt" slot-scope="obj">
                            {{
                                getDateTime(obj.createdAt)
                            }}
                        </div>
                    </a-table>
				</a-card>
			</div>

			<div class="profile mt-5" v-if="timeline.length">
                <a-card>
                    <h4>Timeline</h4>
					<hr
                        class="mt-3"
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />
					<a-row class="mt-3">
						<a-col :span="8" class="mt-4">
							<a-timeline>
								<div class="dF cursor-pointer" v-for="(activity, i) in timeline" :key="i" @click="onActivitySelect(activity)">
									<div class="mr-3" style="margin-top: -5px; color: #9EA0A5; font-size: medium; min-width: 135px;">
										{{ activity.date }}
									</div>
									<a-timeline-item>
										<div class="activity" :class="activity.id === (selectedActivity && selectedActivity.id) ? 'selected-activity' : ''">
											{{ activity.title }}
										</div>
									</a-timeline-item>
								</div>
							</a-timeline>
						</a-col>
						<a-col :span="16">
							<div v-if="selectedActivity">
								<a-card :title="`${selectedActivity.title} - ${selectedActivity.date}`">
									<a-row>
				                        <a-col :span="$mq == 'sm' ? 12 : 16">
				                            <b>Package options </b>
				                        </a-col>
				                        <a-col
				                            :span="$mq == 'sm' ? 4 : 6"
				                            style="text-align: right"
				                        >
				                            <b>Subtotal</b>
				                        </a-col>
				                    </a-row>
									<hr
				                        v-if="$mq != 'sm'"
				                        style="margin-left: -24px; margin-right: -24px"
				                    />
									<a-row v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.package">
				                        <a-col :span="$mq == 'sm' ? 12 : 16">
				                            <div class="text-primary">RECURRING FEES</div>
				                            {{ selectedActivity.invoice.details.package.name }}
				                        </a-col>
				                        <a-col
				                            :span="$mq == 'sm' ? 4 : 6"
				                            style="text-align: right"
				                            class="text-black"
				                        >
				                            <b
				                                >${{
				                                    selectedActivity.invoice.details.package.price
				                                }}</b
				                            >
				                        </a-col>
				                    </a-row>
									<hr
				                        v-if="$mq != 'sm' && selectedActivity.invoice.details && selectedActivity.invoice.details.package"
				                        style="margin-left: -24px; margin-right: -24px"
				                    />

									<div
				                        v-for="(
				                            additional, index
				                        ) in (selectedActivity.invoice.details && selectedActivity.invoice.details.selectedPackageExtras || [])"
				                        :key="index"
				                    >
				                        <a-row>
				                            <a-col :span="$mq == 'sm' ? 12 : 16">
				                                <span
				                                    v-if="additional.name === 'Application Programming Interface (API Calls)' || additional.name === 'API Calls' || additional.name === 'Online Video Chat Support'"
				                                >
				                                    <div class="text-primary">
				                                        ADD ON FEES
				                                    </div>
				                                    {{ additional.name }}
				                                </span>
				                                <span
				                                    v-else-if="additional.name === 'Onboarding'"
				                                >
				                                    <div class="text-primary">
				                                        ONE TIME FEES
				                                        <a-tooltip overlayClassName="change-tooltip-color">
				                                            <template slot="title">
				                                                One time fee (Applicable if you
				                                                are a first time user of
				                                                Bildhive)
				                                            </template>
				                                            <a-icon
				                                                type="question-circle"
				                                                style="
				                                                    font-size: 12px;
				                                                    color: black;
				                                                    margin-left: 5px;
				                                                "
				                                            />
				                                        </a-tooltip>
				                                    </div>
				                                    {{ additional.name }}
				                                </span>
				                                <span
				                                    v-else-if="
				                                        additional.name === 'Lot/Door Fee'
				                                    "
				                                >
				                                    <div class="text-primary">
				                                        <a-popover placement="right">
				                                            <template slot="title">
				                                                <h3
				                                                    class="
				                                                        text-primary text-center
				                                                    "
				                                                >
				                                                    {{
				                                                        selectedInvoice.instance.productType ==
				                                                        "highrise"
				                                                            ? "DOOR FEES"
				                                                            : "LOT FEES"
				                                                    }}
				                                                </h3>
				                                            </template>
				                                            <template slot="content">
				                                                <div
				                                                    style="
				                                                        text-align: justify;
				                                                        width: 400px;
				                                                    "
				                                                >
				                                                    Includes 50 lots/doors per
				                                                    development project.
				                                                    Additional lots/doors are
				                                                    charged on a per door basis
				                                                    starting at $30 per door.
				                                                </div>
				                                                <div
				                                                    class="
				                                                        modal-list
				                                                        d-flex
				                                                        my-2
				                                                    "
				                                                >
				                                                    <ul class="p-0">
				                                                        <li
				                                                            class="
				                                                                modal-list-title
				                                                            "
				                                                        >
				                                                            Door Fee
				                                                        </li>
				                                                        <li>First 50</li>
				                                                        <li>51 – 100</li>
				                                                        <li>101 – 150</li>
				                                                        <li>151 – 200</li>
				                                                        <li>201+</li>
				                                                    </ul>
				                                                    <ul>
				                                                        <li
				                                                            class="
				                                                                modal-list-title
				                                                            "
				                                                        >
				                                                            Billed at
				                                                        </li>
				                                                        <li>$0</li>
				                                                        <li>$30</li>
				                                                        <li>$25</li>
				                                                        <li>$20</li>
				                                                        <li>$15</li>
				                                                    </ul>
				                                                </div>
				                                                <div
				                                                    style="
				                                                        text-align: justify;
				                                                        width: 400px;
				                                                    "
				                                                >
				                                                    Each project subscription is
				                                                    inclusive of 50 lots/doors.
				                                                    Thereafter, a per door
				                                                    charge applies. The charge
				                                                    is based on the number of
				                                                    lots/doors to be processed
				                                                    and supported in your
				                                                    development project. The
				                                                    Door fee is a one-time fee
				                                                    that is over and above your
				                                                    monthly subscription fee and
				                                                    it is charged upon initial
				                                                    project set up.
																	Note: Currency rates apply.
				                                                </div>
				                                            </template>
				                                            {{
				                                                selectedInvoice.instance && selectedInvoice.instance.productType ==
				                                                "highrise"
				                                                    ? "DOOR"
				                                                    : "LOT"
				                                            }}
				                                            FEES
				                                            <a-icon
				                                                type="question-circle"
				                                                style="
				                                                    font-size: 12px;
				                                                    color: black;
				                                                    margin-left: 5px;
				                                                "
				                                            />
				                                        </a-popover>
				                                    </div>
				                                    {{
				                                        selectedInvoice.instance && selectedInvoice.instance.productType ==
				                                        "highrise"
				                                            ? `${additional.quantity} doors`
				                                            : `${additional.quantity} lots`
				                                    }}
				                                    total
				                                </span>
				                                <span
				                                    v-else-if="
				                                        additional.name === 'Training Hours'
				                                    "
				                                >
				                                    <div class="text-primary">
				                                        TRAINING HOUR BLOCKS
				                                        <a-tooltip overlayClassName="change-tooltip-color">
				                                            <template slot="title">
				                                                Additional training hours are
				                                                charged in 4 hour blocks @
				                                                $500/block.
																<br/>
																Note: Currency rates apply.
				                                            </template>
				                                            <a-icon
				                                                type="question-circle"
				                                                style="
				                                                    font-size: 12px;
				                                                    color: black;
				                                                    margin-left: 5px;
				                                                "
				                                            />
				                                        </a-tooltip>
				                                    </div>
				                                    {{ additional.quantity }}
				                                    Blocks ({{ additional.quantity * 4 }} Hours)
				                                </span>
												<span
				                                    v-else-if="
				                                        additional.name === 'Email sends'
				                                    "
				                                >
				                                    <div class="text-primary tT">
				                                        {{ additional.name }}
				                                    </div>
				                                    {{ additional.quantity }} Emails
				                                </span>
												<span
				                                    v-else
				                                >
				                                    <div class="text-primary tT">
				                                        {{ additional.name }}
				                                    </div>
				                                    {{ additional.quantity }}
				                                </span>
				                            </a-col>
				                            <a-col
				                                :span="$mq == 'sm' ? 4 : 6"
				                                style="text-align: right"
				                                class="text-black"
				                            >
				                                <b>${{ additional.price && additional.price.toLocaleString("en-US", {
																useGrouping: false,
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															}) || (0).toFixed(2) }}</b>
				                            </a-col>
				                        </a-row>
				                        <hr
				                            v-if="$mq != 'sm'"
				                            style="margin-left: -24px; margin-right: -24px"
				                        />
				                    </div>

									<div
										v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.usageDetails"
				                    >
										<a-row>
				                            <a-col :span="$mq == 'sm' ? 12 : 16">
				                                <span>
				                                    <div class="text-primary">
				                                        USAGE FEES
				                                    </div>
				                                    {{ selectedActivity.invoice.details.usageDetails.name }}
				                                </span>
											</a-col>
											<a-col
				                                :span="$mq == 'sm' ? 4 : 6"
				                                style="text-align: right"
				                                class="text-black"
				                            >
				                                <b>${{ selectedActivity.invoice.details.usageDetails.price && selectedActivity.invoice.details.usageDetails.price.toLocaleString("en-US", {
														useGrouping: false,
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													}) || (0).toFixed(2) }}</b>
				                            </a-col>
										</a-row>
										<hr
				                            v-if="$mq != 'sm'"
				                            style="margin-left: -24px; margin-right: -24px"
				                        />
									</div>

									<a-row>
										<a-col :span="$mq == 'sm' ? 6 : 2" />
				                        <a-col
				                            :span="$mq == 'sm' ? 6 : 14"
				                            style="text-align: right"
				                        >
											<p v-if="selectedActivity.invoice.subTotal">Subtotal before tax</p>
				                            <p v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.deductions && selectedActivity.invoice.details.deductions.length">Deductions <span>({{ selectedActivity.invoice.details.deductions.map(d => d.name).join(", ") }})</span></p>
				                            <p v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.appliedDiscounts && selectedActivity.invoice.details.appliedDiscounts.length">Discount <span>({{ selectedActivity.invoice.details.appliedDiscounts.map(d => d.name).join(", ") }})</span></p>
				                            <p v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.taxes && selectedActivity.invoice.details.taxes.length">Tax <span>({{ selectedActivity.invoice.details.taxes.map(t => t.name).join(", ") }})</span></p>
				                            <p v-if="selectedActivity.invoice.promoCode">Promo Code</p>
											<p><b>Total Amount</b></p>
				                            <p>Currency</p>
				                        </a-col>
				                        <a-col
				                            :span="$mq == 'sm' ? 4 : 6"
				                            style="text-align: right"
				                        >
											<p v-if="selectedActivity.invoice.subTotal">${{ selectedActivity.invoice.subTotal }}</p>
											<p class="text-red" v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.deductions && selectedActivity.invoice.details.deductions.length">
				                                -${{
				                                    selectedActivity.invoice.details && selectedActivity.invoice.details.deductions && selectedActivity.invoice.details.deductions
				                                        .reduce((acc, discount) => {
				                                            return acc + discount.price;
				                                        }, 0)
				                                        .toFixed(2) || (0).toFixed(2)
				                                }}
				                            </p>
				                            <p class="text-red" v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.appliedDiscounts && selectedActivity.invoice.details.appliedDiscounts.length">
				                                -${{
				                                    selectedActivity.invoice.details && selectedActivity.invoice.details.appliedDiscounts && selectedActivity.invoice.details.appliedDiscounts
				                                        .reduce((acc, discount) => {
				                                            return acc + discount.price;
				                                        }, 0)
				                                        .toFixed(2) || (0).toFixed(2)
				                                }}
				                            </p>
				                            <p v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.taxes && selectedActivity.invoice.details.taxes.length">
				                                ${{
				                                    selectedActivity.invoice.details && selectedActivity.invoice.details.taxes && selectedActivity.invoice.details.taxes
													.reduce(
				                                        (acc, tax) => {
				                                            return acc + tax.price;
				                                        },
				                                        0
				                                    ).toFixed(2) || (0).toFixed(2)
				                                }}
				                            </p>
											<p>
												<a-tag v-if="selectedActivity.invoice.promoCode" class="m-0">{{selectedActivity.invoice.promoCode}}</a-tag>
												<a-tooltip v-if="getPromoCodeDescription(selectedActivity.invoice.promoCode)" overlayClassName="change-tooltip-color" class="ml-1">
													<template #title>
														{{ getPromoCodeDescription(selectedActivity.invoice.promoCode) }}
													</template>
													<a-icon type="question-circle" style="font-size: 14px" />
												</a-tooltip>
											</p>
				                            <p>
				                                <b>
				                                    ${{ selectedActivity.invoice.total }}
				                                </b>
				                            </p>
				                            <p>{{ selectedActivity.invoice.currency || 'USD' }}</p>
				                        </a-col>
				                    </a-row>
								</a-card>
								<div class="profile mt-5" v-if="selectedActivity.invoice.payments && selectedActivity.invoice.payments.length">
									<h5>Offline Payment {{ selectedActivity.title.split(' ')[1] }}</h5>
									<a-table
				                        :rowKey="(e, i) => e.id"
				                        :columns="paymentsColumns"
				                        :dataSource="selectedActivity.invoice.payments"
				                        class="list-view"
										:loading="loading"
				                    >
				                        <div slot="amount" slot-scope="obj">
				                            ${{ obj.amount && obj.amount.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || 0 }} ({{ selectedActivity.invoice.currency || "USD"}})
				                        </div>
				                        <div slot="paymentDate" slot-scope="obj">
				                            {{
				                                getDateTime(obj.paymentDate)
				                            }}
				                        </div>
										<div slot="paymentMode" slot-scope="obj">
				                            {{ obj.paymentMode === 'check' ? 'Check' :  obj.paymentMode === 'bank_transfer' ? 'Bank Transfer' : 'Cash'  }}
				                        </div>
										<div slot="note" slot-scope="obj">
				                            {{ obj.note  }}
				                        </div>
				                    </a-table>
								</div>
								<div class="profile mt-5" v-if="selectedActivity.invoice.refunds && selectedActivity.invoice.refunds.length">
									<h5>Invoice Refunds</h5>
									<a-table
				                        :rowKey="(e, i) => e.id"
				                        :columns="refundColumns"
				                        :dataSource="selectedActivity.invoice.refunds"
				                        class="list-view"
										:loading="loading"
				                    >
				                        <div slot="amount" slot-scope="obj">
				                            ${{ obj.amount && obj.amount.toLocaleString("en-US", {
												useGrouping: false,
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											}) || 0 }} ({{ selectedInvoice.currency || "USD"}})
				                        </div>
										<div slot="reason" slot-scope="obj">
				                            {{ obj.reason  }}
				                        </div>
										<div slot="createdAt" slot-scope="obj">
				                            {{
				                                getDateTime(obj.createdAt)
				                            }}
				                        </div>
				                    </a-table>
								</div>
							</div>
						</a-col>
					</a-row>
				</a-card>
			</div>
        </div>
		<BillingModal
			v-if="paymentMethodModal.show"
            :show="paymentMethodModal.show"
            :load="paymentMethodModal.load"
            :action="paymentMethodModal.action"
            :modalTitle="paymentMethodModal.title"
            :modalOkText="paymentMethodModal.okText"
            :states="states"
            :provinces="provinces"
            :paymentMethodData="paymentMethodToUpdate"
            @close="resetPaymentMethodModalData"
            @cardPayment="updatePaymentMethod"
        ></BillingModal>

		<a-modal
            :footer="null"
            :centered="true"
            :visible="switchCardModal.visible"
            :width="720"
            @cancel="closeSwitchCardModal"
        >
            <h5 class="mb-3">Update Payment Method</h5>
            <div
                class="text-dark mb-5"
                v-if="Object.keys(switchCardModal.card).length != 0"
            >
                {{
                    `Choose a new payment method in replace of ${
                        switchCardModal.card.details.ccBrand == "visa"
                            ? "Visa"
                            : switchCardModal.card.details.ccBrand ==
                              "mc"
                            ? "Mastercard"
                            : "American Express"
                    } ${getCardNumber(
                        switchCardModal.card.details.cardNo
                    )}`
                }}
            </div>
            <div
                :style="paymentI == 0 ? 'margin-top:1rem' : ''"
                style="display: block"
                v-for="(payment, paymentI) in paymentMethods"
                :key="payment.value + paymentI"
                :value="payment.value"
            >
                <div
                    class="dF aC"
                    style="justify-content: space-between"
                >
                    <div class="dF aC">
                        <a-radio
                            :checked="
                                switchCardModal.option == payment.value
                            "
                            @change="changeRadio"
                            :value="payment.value"
                        />
                        <div>
                            <div>{{ payment.label }}</div>
                            <div
                                @click="updateCard(payment.value)"
                                v-if="
                                    paymentI !=
                                    paymentMethods.length - 1
                                "
                                style="
                                    color: var(--orange);
                                    cursor: pointer;
                                "
                            >
                                Update card info
                            </div>
                        </div>
                    </div>
                    <i
                        @click="updateCard(payment.value)"
                        v-if="paymentI != paymentMethods.length - 1"
                        style="color: var(--orange); cursor: pointer"
                        class="fe fe-edit-2"
                    />
                </div>
                <hr v-if="paymentI != paymentMethods.length - 1" />
            </div>
            <div class="mt-5 dF aC legals">
                By continuing, you agree to the Bildhive&nbsp;
                <a
                    href="https://admin.bildhive.dev/system/terms"
                    target="_"
                    >Terms of Service</a
                >.
            </div>
            <div class="dF aC mt-5 dF jE">
                <a-button class="mr-3" @click="closeSwitchCardModal"
                    >CANCEL</a-button
                >
                <a-button
                    :loading="switchCardModal.loading"
                    @click="updatePaymentMethodCard"
                    type="primary"
                    >UPDATE</a-button
                >
            </div>
        </a-modal>
		<div>
			<InvoicePDF :invoice="invoiceToDownload" :userPaymentMethods ="userPaymentMethods" @close="invoiceToDownload = null; previewModal = false;" :previewModal="previewModal"/>
		</div>
    </div>
</template>

<script>
import BillingModal from "@/views/instance/billingmodal";
import valid from "card-validator";
import InvoicePDF from "@/components/common/InvoicePDF";
import AllFeaturesList from "@/components/common/allFeaturesList";
import moment from "moment"
import { mapState } from "vuex";

export default {
    components: { BillingModal, InvoicePDF, AllFeaturesList },
    props: {
        selectedInvoice: {
            type: Object,
            default: () => ({}),
        },
		userPaymentMethods: {
			type: Array,
			default: () => []
		},
		invoices: {
			type: Array,
			default: () => []
		}
    },

    data() {
        return {
			loading: false,
            previewModal: false,
            couponCode: "",
            validCouponCode: "",
            loadModal: false,
            changeInPlan: false,
			updateAddons: false,
            showAllFeatures: false,
            packages: [],
            packageExtras: [],
            selectedPackageId: null,
            current: 1,
            lotsOrDoors: 0,
            instanceNotFound: false,
            trainingHourBlocks: 0,
			invoiceToDownload: null,
			existingPaymentMethods: [],
			paymentMethodToUpdate: null,
			switchCardModal: {
                card: {},
                visible: false,
                option: "",
                loading: false,
            },
			paymentMethodModal: {
                show: false,
                load: false,
                title: "Add New Card",
                okText: "Add Card",
                action: "add",
                paymentMethodId: "",
            },
			currentPaymentMethod: {},
            steps: [
                {
                    title: "Step 1",
                    description: "Select your plan",
                },
                {
                    title: "Finish",
                    description: "How would you like to pay?",
                },
            ],
			addOnSteps: [
                {
                    title: "Step 1",
                    description: "Select your Addons",
                },
                {
                    title: "Finish",
                    description: "How would you like to pay?",
                },
            ],
            states: [
                { name: "Alabama", value: "AL" },
                { name: "Alaska", value: "AK" },
                { name: "Arizona", value: "AZ" },
                { name: "Arkansas", value: "AR" },
                { name: "California", value: "CA" },
                { name: "Colorado", value: "CO" },
                { name: "Connecticut", value: "CT" },
                { name: "Delaware", value: "DE" },
                { name: "District of Columbia", value: "DC" },
                { name: "Florida", value: "FL" },
                { name: "Georgia", value: "GA" },
                { name: "Hawaii", value: "HI" },
                { name: "Idaho", value: "ID" },
                { name: "Illinois", value: "IL" },
                { name: "Indiana", value: "IN" },
                { name: "Iowa", value: "IA" },
                { name: "Kansas", value: "KS" },
                { name: "Kentucky", value: "KY" },
                { name: "Louisiana", value: "LA" },
                { name: "Maine", value: "ME" },
                { name: "Maryland", value: "MD" },
                { name: "Massachusetts", value: "MA" },
                { name: "Michigan", value: "MI" },
                { name: "Minnesota", value: "MN" },
                { name: "Mississippi", value: "MS" },
                { name: "Missouri", value: "MO" },
                { name: "Montana", value: "MT" },
                { name: "Nebraska", value: "NE" },
                { name: "Nevada", value: "NV" },
                { name: "New Hampshire", value: "NH" },
                { name: "New Jersey", value: "NJ" },
                { name: "New Mexico", value: "NM" },
                { name: "New York", value: "NY" },
                { name: "North Carolina", value: "NC" },
                { name: "North Dakota", value: "ND" },
                { name: "Ohio", value: "OH" },
                { name: "Oklahoma", value: "OK" },
                { name: "Oregon", value: "OR" },
                { name: "Pennsylvania", value: "PA" },
                { name: "Rhode Island", value: "RI" },
                { name: "South Carolina", value: "SC" },
                { name: "South Dakota", value: "SD" },
                { name: "Tennessee", value: "TN" },
                { name: "Texas", value: "TX" },
                { name: "Utah", value: "UT" },
                { name: "Vermont", value: "VT" },
                { name: "Virginia", value: "VA" },
                { name: "Washington", value: "WA" },
                { name: "West Virginia", value: "WV" },
                { name: "Wisconsin", value: "WI" },
                { name: "Wyoming", value: "WY" },
            ],
            provinces: [
                { name: "Alberta", value: "AB" },
                { name: "British Columbia", value: "BC" },
                { name: "Manitoba", value: "MB" },
                { name: "New Brunswick", value: "NB" },
                { name: "Newfoundland and Labrador", value: "NL" },
                { name: "Nova Scotia", value: "NS" },
                { name: "Ontario", value: "ON" },
                { name: "Prince Edward Island", value: "PE" },
                { name: "Quebec", value: "QC" },
                { name: "Saskatchewan", value: "SK" },
                { name: "Northwest Territories", value: "NT" },
                { name: "Nunavut", value: "NU" },
                { name: "Yukon", value: "YT" },
            ],
			paymentsColumns: [
				{
					title: "Amount",
					key: "amount",
					scopedSlots: { customRender: "amount" },
					sorter: (a, b) => a.amount - b.amount,
				},
				{
					title: "Payment Date",
					key: "paymentDate",
					scopedSlots: { customRender: "paymentDate" },
					sorter: (a, b) => a.paymentDate - b.paymentDate,
				},
				{
					title: "Payment Mode",
					key: "paymentMode",
					scopedSlots: { customRender: "paymentMode" },
					sorter: (a, b) =>
						a.paymentMode < b.paymentMode
							? -1
							: a.paymentMode > b.paymentMode
								? 1
								: 0,
				},
				{
					title: "Note",
					key: "note",
					scopedSlots: { customRender: "note" },
					sorter: (a, b) =>
						a.note < b.note
							? -1
							: a.note > b.note
								? 1
								: 0,
				}
			],
			refundColumns: [
				{
					title: "Amount",
					key: "amount",
					scopedSlots: { customRender: "amount" },
					sorter: (a, b) => a.amount - b.amount,
				},
				{
					title: "Refund Date",
					key: "createdAt",
					scopedSlots: { customRender: "createdAt" },
					sorter: (a, b) => a.createdAt - b.createdAt,
				},
				{
					title: "Refund Reason",
					key: "reason",
					scopedSlots: { customRender: "reason" },
					sorter: (a, b) =>
						a.reason < b.reason
							? -1
							: a.reason > b.reason
								? 1
								: 0,
				},
			],
            instance: {},
            instancePriceDetails: {},
            selectedExtraPackages: {},
			timeline:[],
			selectedActivity: null,
			selectedPaymentMethod: null,
        };
    },

    computed: {
		...mapState(["promoCodes"]),

        packageStyle() {
            return {
                "grid-template-columns": `repeat(${this.packages.length}, minmax(0, 1fr))`,
                padding:
                    this.packages.length === 1
                        ? "0 33%"
                        : this.packages.length === 2
                        ? "0 16.5%"
                        : "0 0",
            };
        },

        addOnsPackages() {
            return this.extraPackages.filter((x) => x.type === "addon");
        },

        extraPackages() {
            const self = this;
            return this.packageExtras.map((x) => {
                const find = self.selectedInvoice && self.selectedInvoice.instance && self.selectedInvoice.instance.selectedPackageExtras.find(
                    (y) => y.package_extra.id === x.id
                );
                return {
                    ...x,
                    selected: find ? true : false,
                };
            });
        },

        additionalPackages() {
            if (!this.instancePriceDetails) {
                return [];
            }
            let result = JSON.parse(JSON.stringify(this.instancePriceDetails.additional || []));

            if (this.instancePriceDetails && this.instancePriceDetails.featureBlocks && this.instancePriceDetails.featureBlocks.length) {
                this.instancePriceDetails.featureBlocks.forEach((x) => {
                    if (x.name === "Training hours") {
                        result.push({
                            ...x,
                            name: "Training Hours",
                            quantity: x.quantity,
                        });
                    } else {
						result.push({ ...x, quantity: x.quantity ? x.quantity : (x.value && Number(x.value)) });
                    }
                });
            }

            return result;
        },

		paymentMethods() {
            let options = [];
            if (
                this.existingPaymentMethods &&
                this.existingPaymentMethods.length != 0 &&
                this.switchCardModal &&
                this.switchCardModal.card &&
                Object.keys(this.switchCardModal.card).length != 0
            ) {
                this.existingPaymentMethods.forEach((pay) => {
                    let obj = {};
                    if (pay.details.ccBrand == "visa") obj.name = "Visa";
                    else if (pay.details.ccBrand == "mc")
                        obj.name = "Mastercard";
                    else if (pay.details.ccBrand == "amex")
                        obj.name = "American Express";
                    obj.label = `${obj.name} ${this.getCardNumber(
                        pay.details.cardNo
                    )}`;
                    obj.value = pay.id;
                    if (obj.value != this.switchCardModal.card.id)
                        options.push(obj);
                });
                let newCard = {
                    label: "Add credit or debit card",
                    value: "newCard",
                };
                options.push(newCard);
            }
            return options;
        },

		lotDoorPackage() {
			const found = this.packageExtras.find((x) => x.identifier === "lot_door_fee");
			return found || null;
		},

		addOnNextButtonDisabled() {
			let isDisabled = true;

			if (this.selectedExtraPackages['addOns']) {
				const selectedAddOns = this.selectedExtraPackages['addOns'].some(a => a.selected)
				if (selectedAddOns || +this.lotsOrDoors || +this.trainingHourBlocks) {
					isDisabled = false;
				}
			} else if (+this.lotsOrDoors || +this.trainingHourBlocks) {
				isDisabled = false;
			}
			return isDisabled
		},

		promoCodeDescription() {
			if (this.couponCode && this.validCouponCode === "success") {
				const foundPC = this.promoCodes.find(p => p.code == this.couponCode)
				if (foundPC) return foundPC.description || ''
			}
			return ''
		}
    },

    created() {
		this.currentPaymentMethod = this.selectedInvoice && this.selectedInvoice.instance && this.selectedInvoice.instance.paymentMethod
		this.selectedPaymentMethod = this.currentPaymentMethod && this.currentPaymentMethod.id || null;
		this.existingPaymentMethods = this.userPaymentMethods;
		this.updatedExtraPackages();
		this.getTimeline();
    },

    methods: {
		updatedExtraPackages() {
			if (this.selectedInvoice.details && this.selectedInvoice.details.featureBlocks && this.selectedInvoice.details.featureBlocks.length) {
				if (!this.selectedInvoice.details.selectedPackageExtras) {
					this.selectedInvoice.details.selectedPackageExtras = []
				}
				this.selectedInvoice.details.featureBlocks.forEach(
					(x) => {
						if (x.name === "Training hours") {
							const index = this.selectedInvoice.details.selectedPackageExtras.findIndex(p => p.name === "Training Hours");
							if (index >= 0) {
								this.selectedInvoice.details.selectedPackageExtras[index] = {
									...x,
									name: "Training Hours",
									quantity: x.quantity,
								}
							} else {
								this.selectedInvoice.details.selectedPackageExtras.push(
									{
										...x,
										name: "Training Hours",
										quantity: x.quantity,
									}
								);
							}
						} else {
							this.selectedInvoice.details.selectedPackageExtras.push({ ...x, quantity: x.quantity ? x.quantity : (x.value && Number(x.value)) });
						}
					}
				);
			}
		},

        onCheckboxChange(event, extraPackage, packageId) {
            const obj = { ...extraPackage, selected: event.target.checked };

            const index = this.selectedExtraPackages[packageId].findIndex(
                (item) => item.id === obj.id
            );
            if (index === -1) {
                this.selectedExtraPackages[packageId].push(obj);
            } else {
                this.selectedExtraPackages[packageId][index] = obj;
            }
        },

        makePayment(id) {
            this.$message.info("Payment initiated.");
            this.$api
                .get(`/invoices/tryAgain/${id}`)
                .then(({ data }) => {
					if (data && data.success) {
                        this.$message.success("Payment done successfully.");
						this.selectedInvoice.paid = data.paid;
                    } else {
                        this.$message.error(`Payment failed. ${data && data.message && data.message.message || ''}`);
                    }
                })
                .catch((err) => {
                    // this.$message.error(
                    //     (err.response &&
                    //         err.response.data &&
                    //         err.response.data.message) ||
                    //         "Error while making payment for the selectedInvoice. Please try again!"
                    // );
                    console.error(
                        "Error while making payment for the invoice",
                        err
                    );
                });
        },

        resetData() {
            this.selectedPackageId = null;
            this.lotsOrDoors = 0;
            this.trainingHourBlocks = 0;
            this.current = 1;
            this.couponCode = "";
			this.selectedExtraPackages = {};
			this.validCouponCode = "";
        },

        changePlan() {
			if(this.instanceNotFound){
				return
			}
            if (
				this.selectedInvoice &&
				this.selectedInvoice.instance &&
                this.selectedInvoice.instance.projectType &&
                this.selectedInvoice.instance.productType
            ) {
                this.resetData();
                this.changeInPlan = true;
                this.packages = [];
                this.packageExtras = [];

                const promises = [
                    this.$api.get(
                        `/packages?projectType=${this.selectedInvoice.instance.projectType}&productType=${this.selectedInvoice.instance.productType}&currency=${this.selectedInvoice.instance.currency || 'USD'}`
                    ),
                    this.$api.get(`/package-extras?currency=${this.selectedInvoice.instance.currency || 'USD'}`),
                ];

                Promise.all(promises)
                    .then(([packages, packageExtras]) => {
                        this.packages = packages.data;
                        this.packageExtras = packageExtras.data;

                        const lot_door_fee = this.packageExtras.find(
                            (pe) => pe.identifier === "lot_door_fee"
                        );

                        this.packages.forEach((p) => {
                            this.selectedExtraPackages[p.id] = lot_door_fee
                                ? [lot_door_fee]
                                : [];
                        });
						this.packages.sort((a, b) => b.price - a.price);
                    })
                    .catch((err) => {
                        console.error("Error while fetching packages", err);
                    });
            } else {
                this.$message.error(
                    "No valid plan details found. Please contact Administrator!"
                );
            }
        },

        next() {
            if (this.current === 1 && this.selectedPackageId) {
                this.fetchPriceDetails();
                this.current++;
                return;
            } else if (this.current === 2) {
                this.loadModal = true;

                const instanceParams = {
                    packageId: this.selectedPackageId,
                    promoCode: this.couponCode,
                    numberOfTrainingHoursBlock: this.trainingHourBlocks
                        ? parseInt(this.trainingHourBlocks)
                        : 0,
                    packageExtras: this.selectedExtraPackages[
                        this.selectedPackageId
                    ]
                        .map((extras) => {
                            if (extras.selected) {
                                return {
                                    id: extras.id,
                                    quantity: 1,
                                };
                            } else if (
                                this.lotsOrDoors &&
                                extras.identifier === "lot_door_fee"
                            ) {
                                return {
                                    id: extras.id,
                                    quantity: parseInt(this.lotsOrDoors),
                                };
                            } else {
                                return null;
                            }
                        })
                        .filter((pe) => pe),
					paymentMode: this.selectedInvoice.paymentMode
                };

				if(this.selectedInvoice.paymentMode === 'online') {
					instanceParams.paymentMethod = this.selectedPaymentMethod
				}

                this.$api
                    .put(
                        `/instances/${this.selectedInvoice && this.selectedInvoice.instance && this.selectedInvoice.instance.id}/change-plan`,
                        instanceParams
                    )
                    .then(({ data }) => {
						this.$message.success(
                            "Project was successfully updated."
                        );
                        this.current--;
                        this.changeInPlan = false;
                        this.loadModal = false;
                        this.resetData();
						this.$emit('back', null, true);
                    })
                    .catch((err) => {
                        console.error(
                            "Error occurred while updating plan",
                            err
                        );
						this.$message.error(
                            (err.response &&
                                err.response.data &&
                                err.response.data.message) ||
                                "Error occurred while updating Project plan. Please try again!"
                        );
                        this.loadModal = false;
                    });
            }
        },

        fetchPriceDetails() {
            const instanceParams = {
				currency: this.selectedInvoice.instance.currency,
                instanceId: this.selectedInvoice.instance && this.selectedInvoice.instance.id,
                packageId: this.selectedPackageId,
                promoCode: this.couponCode,
				partner: (this.selectedInvoice.instance.partner && this.selectedInvoice.instance.partner.id) || null,
                numberOfTrainingHoursBlock: this.trainingHourBlocks
                    ? parseInt(this.trainingHourBlocks)
                    : 0,
                packageExtras: this.selectedExtraPackages[
                    this.selectedPackageId
                ]
                    .map((extras) => {
                        if (extras.selected) {
                            return {
                                id: extras.id,
                                quantity: 1,
                            };
                        } else if (
                            this.lotsOrDoors &&
                            extras.identifier === "lot_door_fee"
                        ) {
                            return {
                                id: extras.id,
                                quantity: parseInt(this.lotsOrDoors),
                            };
                        } else {
                            return null;
                        }
                    })
                    .filter((pe) => pe),
            };
            this.validCouponCode = this.couponCode ? "validating" : "";

            this.$api
                .post("/instances/price", instanceParams)
                .then(({ data }) => {
                    this.instancePriceDetails = data || {};
                    if (this.couponCode) {
                        data.discounts.forEach((discount) => {
                            if (discount.code === this.couponCode) {
                                this.validCouponCode = "success";
                            }
                        });
                        if (this.validCouponCode === "validating") {
                            this.validCouponCode = "error";
                            this.$message.error("Invalid coupon code.");
                        } else if (this.validCouponCode === "success") {
                            this.$message.success(
                                "Coupon code applied successfully."
                            );
                        }
                    }
                })
                .catch((err) => {
                    if (err.response && err.response.data && err.response.data.statusCode === 400) {
                        this.validCouponCode = this.couponCode ? "error" : "";
                        this.$message.error(err.response.data.message);
                    }
                    console.error("Error while creating pricing details", err);
                });
        },

        updatePaymentMethod(paymentMethodDetails) {
            let expiry = valid.expirationDate(paymentMethodDetails.expiry);
            let sendCardOBJ = JSON.parse(JSON.stringify(paymentMethodDetails));
            let { month, year } = expiry;
            sendCardOBJ.expiry = `${month}/${year}`;

            this.loadModal = true;
			let self = this;

			if (this.paymentMethodModal.action === "add") {
				this.$api
	                .post("/payment-methods", {
	                    details: sendCardOBJ,
						default: sendCardOBJ.default || false
	                })
	                .then(({ data }) => {
	                    if (data.success === false) {
	                        let errorMessage = data.result.message;
	                        errorMessage = errorMessage.split("\n");
	                        errorMessage.forEach((mess) => {
	                            self.$message.error(mess);
	                        });
	                    } else {
							this.$message.success('Payment method added successfully!');
						}
						this.existingPaymentMethods.push(data);
						this.resetPaymentMethodModalData();
	                })
	                .catch((err) => {
	                    this.$message.error(
	                        (err.response &&
	                            err.response.data &&
	                            err.response.data.message) ||
	                            "Error while creating payment method. Try Again!"
	                    );
	                    this.loadModal = false;
	                });
			} else if (this.paymentMethodModal.action === "edit") {
                this.$api
                    .put(`/payment-methods/${this.paymentMethodModal.paymentMethodId}`, {
                        details: sendCardOBJ,
						default: sendCardOBJ.default || false
                    })
                    .then(async ({ data }) => {
                        if (data.success == false) {
                            let errorMessage = data.result.message;
                            errorMessage = errorMessage.split("\n");
                            errorMessage.forEach((mess) => {
                                self.$message.error(mess);
                            });
                        } else {
							this.$message.success(
		                        "payment method details updated successfully."
		                    );
                            self.existingPaymentMethods.forEach((pm, index) => {
                                if (pm.id === data.id) {
                                    self.existingPaymentMethods[index] = data;
                                }
                            });
							if(!this.changeInPlan) {
								this.switchCardModal.visible = true;
								this.switchCardModal.card = data;
							}
							this.resetPaymentMethodModalData();
                        }
                    })
                    .catch((err) => {
                        this.$message.error(
                            "Error while updating payment method. Try Again!"
                        );
                        self.paymentMethodModal.load = false;
                    });
            }
        },

		deleteCard(card) {
            if (card.instances && card.instances.length > 0) {
                this.switchCardModal.visible = true;
                this.switchCardModal.card = card;
            } else if (!card.instances || card.instances.length == 0) {
                let self = this;
                this.$confirm({
                    title: "Delete Payment Method",
                    content: (h) => <div>Do you want to delete this card?</div>,
                    okText: "DELETE",
                    okType: "danger",
                    cancelText: "CANCEL",
                    centered: true,
                    onOk() {
                        self.confirmDelete(card);
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
            }
        },
        confirmDelete(item) {
            this.$api
                .delete(`/payment-methods/${item.id}`)
                .then(({ data }) => {
                    this.existingPaymentMethods =
                        this.existingPaymentMethods.filter(
                            (x) => x.id !== data.id
                        );
                })
                .catch((err) => {
                    this.$message.error(this.$err(err));
                });
        },

		cardNumberDetails(card) {
            if (card) {
                let cardNo = card.cardNo;
                let cardType = "";

                if (card.ccBrand === "visa") {
                    cardType = "Visa";
                } else if (card.ccBrand === "mc") {
                    cardType = "MasterCard";
                } else if (card.ccBrand === "amex") {
                    cardType = "AmericanExpress";
                }

                return (
                    cardType +
                    " ending in " +
                    cardNo.substring(cardNo.length - 4)
                );
            }
        },

		onChange(e) {
            this.selectedPaymentMethod = e.target.value;
        },

		updateCard(id) {
			let found = this.existingPaymentMethods.find((x) => x.id == id);
            if (found) {
				this.editCard(found)
            }
        },

		updatePaymentMethodCard() {
			if (this.switchCardModal.option == "")
                return this.$message.error("Please select an option.");
            else if (this.switchCardModal.option == "newCard"){
				this.resetPaymentMethodModalData();
                this.paymentMethodModal.show = true;
			} else {
                this.switchCardModal.loading = true;

                this.$api
                    .post(
                        `/payment-methods/${this.selectedInvoice.instance && this.selectedInvoice.instance.id}/change-card/${this.switchCardModal.option}`)
                    .then(({ data }) => {
						this.$message.success("Successfully updated selected payment method");
                        this.currentPaymentMethod = data.paymentMethod;
                        this.closeSwitchCardModal();
                    }).catch((error) => {
						this.switchCardModal.loading = false;
						console.error("Error while updating payment method for the instance", error);
	                    this.$message.error(
	                        (error.response &&
	                            error.response.data &&
	                            error.response.data.message) ||
	                            "Error while updating payment method for the instance. please try again!"
	                    );
					})
            }
		},

		async getTimeline() {
			try {
				const instanceId = this.selectedInvoice && this.selectedInvoice.instance && this.selectedInvoice.instance.id;
				if(!instanceId){
					return;
				}
				const { data } = await this.$api.get(`timelines/${instanceId}/billing/${instanceId}`);
				this.generateTimelineData(data);
			} catch (error) {
				this.$message.error(
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					"Error while getting timeline details. Try Again!"
				);
			}
		},

		generateTimelineData(activities) {
			let timeline = [];

			activities.forEach(activity => {
				if (activity.data && activity.data.action) {
					const id = activity.createdAt
					let invoice = activity.data.invoice;

					if (activity.data.payment) {
						invoice.payments = [activity.data.payment]
					}

					if (activity.data.refund) {
						invoice.refunds = [{...activity.data.refund, createdAt: activity.createdAt}]
					}

					if (!invoice.details.selectedPackageExtras) {
						invoice.details.selectedPackageExtras = []
					}

					if (!invoice.currency) {
						const found = this.invoices.find(i => i.id === invoice.id)
						if (found) {
							invoice.currency = found.currency
						}
					}

					if(invoice && invoice.details && invoice.details.featureBlocks){
						invoice.details.featureBlocks.forEach(
							(x) => {
								if (x.name === "Training hours") {
									const index = invoice.details.selectedPackageExtras.findIndex(p => p.name === "Training Hours");
									if (index >= 0) {
										invoice.details.selectedPackageExtras[index] = {
											...x,
											name: "Training Hours",
											quantity: x.quantity,
										}
									} else {
										invoice.details.selectedPackageExtras.push(
											{
												...x,
												name: "Training Hours",
												quantity: x.quantity,
											}
										);
									}
								} else {
									invoice.details.selectedPackageExtras.push({ ...x, quantity: x.quantity ? x.quantity : (x.value && Number(x.value)) });
								}
							}
						);
					}

					const actions = {
						instance_created: 'Project Created',
						instance_renew: 'Project Renew',
						package_updated: 'Project Package Updated',
						feature_charge: 'New Feature Charged',
						usage_invoice: 'New Usage Charged',
						payment_received: 'Payment Received',
						payment_updated: 'Payment Updated',
						payment_deleted: 'Payment Deleted',
						invoice_refunded: 'Invoice Refunded',
						addons_invoice: 'Addons Invoice',
					}

					timeline.push({
						id,
						date: this.textDate(activity.createdAt),
						title: actions[activity.data.action] || activity.data.action,
						invoice
					})
				}
			})

			this.timeline = timeline;

			if (this.timeline.length) {
				this.onActivitySelect(this.timeline[0])
			}
		},

		changeRadio(e) {
            this.switchCardModal.option = e.target.value;
        },

		closeSwitchCardModal() {
            this.switchCardModal.visible = false;
            this.switchCardModal.card = {};
            this.switchCardModal.option = "";
			this.switchCardModal.loading = false;
        },

		editCard(item) {
            this.paymentMethodModal.action = "edit";
            this.paymentMethodModal.okText = "Update";
            this.paymentMethodModal.title = "Edit Card Details";
            this.paymentMethodModal.paymentMethodId = item.id;
            this.paymentMethodModal.show = true;
			this.paymentMethodToUpdate = item.details;
        },

		resetPaymentMethodModalData() {
            this.paymentMethodModal.action = "add";
            this.paymentMethodModal.okText = "Add Card";
            this.paymentMethodModal.title = "Add New Card";
            this.paymentMethodModal.paymentMethodId = "item.id";
            this.paymentMethodModal.show = false;
            this.paymentMethodModal.load = false;
			this.paymentMethodToUpdate = null;
			this.loadModal = false;
        },

		getCardNumber(string) {
            let card = string.substring(string.length - 4, string.length);
            return `****${card}`;
        },

		calculateTotalRefund(refunds) {
            const total = refunds
                .reduce((a, b) => {
                    return a + b.amount;
                }, 0)
                .toFixed(2);
            return total ? "$" + total : "";
        },

		getDate(date) {
            return moment(date).format("DD/M/YYYY");
        },

		getDateTime(date) {
            return moment(date).format("DD/M/YYYY hh:mm A");
        },

        textDate(date){
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let newDate = new Date(date)
			let month = monthNames[Number(newDate.getMonth())]
			return month + ' ' + newDate.getDate() + ', ' + newDate.getFullYear()
        },

		onActivitySelect(activity){
			this.selectedActivity = activity;
		},

		onAddOnCheckboxChange(event, extraPackage) {
			const obj = { ...extraPackage, selected: event.target.checked };

			if (!this.selectedExtraPackages['addOns']) {
				this.$set(this.selectedExtraPackages, 'addOns', [])
			}

			const index = this.selectedExtraPackages['addOns'].findIndex(
				(item) => item.id === obj.id
			);
			if (index === -1) {
				this.selectedExtraPackages['addOns'].push(obj);
			} else {
				this.selectedExtraPackages['addOns'][index] = obj;
			}
		},

		addAddons() {
			this.resetData();
			this.updateAddons = true;
			this.packageExtras = [];

			this.$api.get(`/package-extras?currency=${this.selectedInvoice.instance.currency || "USD"}`).then(({ data }) => {
				this.packageExtras = data;
			})
		},

		async fetchAddOnPriceDetails() {
			try {
				if (!this.selectedExtraPackages['addOns']) {
					this.$set(this.selectedExtraPackages, 'addOns', [])
				}
				const payload = {
					currency: this.selectedInvoice.instance.currency,
					instanceId: this.selectedInvoice.instance.id,
					state: this.selectedInvoice.instance.region,
					country: this.selectedInvoice.instance.country,
					promoCode: this.couponCode,
					partner: (this.selectedInvoice.instance.partner && this.selectedInvoice.instance.partner.id) || null,
					billingOwner: (this.selectedInvoice.instance.billingOwner && this.selectedInvoice.instance.billingOwner.id) || null,
					numberOfTrainingHoursBlock: this.trainingHourBlocks
						? parseInt(this.trainingHourBlocks)
						: 0,
					packageExtras: this.selectedExtraPackages['addOns'].map((extras) => {
						if (extras.selected) {
							return {
								id: extras.id,
								quantity: 1,
							};
						} else {
							return null;
						}
					})
						.filter((pe) => pe),
				};

				if (this.lotsOrDoors && this.lotDoorPackage) {
					payload.packageExtras.push({
						id: this.lotDoorPackage.id,
						quantity: parseInt(this.lotsOrDoors),
					})
				}
				this.validCouponCode = "";
				const { data } = await this.$api.post(`instances/addon-price`, payload)
				this.instancePriceDetails = data;
				if (this.couponCode) {
					data.discounts.forEach((discount) => {
						if (discount.code === this.couponCode) {
							this.validCouponCode = "success";
						}
					});
					if (this.validCouponCode === "validating") {
						this.validCouponCode = "error";
						this.$message.error("Invalid coupon code.");
					} else if (this.validCouponCode === "success") {
						this.$message.success("Coupon code applied successfully.");
					}
				} else {
					this.current++;
				}
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.validCouponCode = this.couponCode ? "error" : "";
					this.$message.error(this.$err(err, `An error occurred while fetching addon price details. Please try again.`))
				}
			}
		},

		async addAddOns() {
			try {
				this.loadModal = true;
				const payload = {
					promoCode: this.couponCode,
					numberOfTrainingHoursBlock: this.trainingHourBlocks
						? parseInt(this.trainingHourBlocks)
						: 0,
					packageExtras: this.selectedExtraPackages['addOns'].map((extras) => {
						if (extras.selected) {
							return {
								id: extras.id,
								quantity: 1,
							};
						} else {
							return null;
						}
					}).filter((pe) => pe),
				};

				if (this.lotsOrDoors && this.lotDoorPackage) {
					payload.packageExtras.push({
						id: this.lotDoorPackage.id,
						quantity: parseInt(this.lotsOrDoors),
					})
				}

				await this.$api.post(`/instances/${this.selectedInvoice.instance.id}/add-addons`, payload)
				this.$message.success("Project Addons was successfully updated.");
				this.current--;
				this.updateAddons = false;
				this.loadModal = false;
				this.resetData();
				this.$emit('back', null, true);
			} catch (err) {
				this.loadModal = false;
				console.error(
					"Error occurred while updating add ons",
					err
				);
				this.$message.error(
					(err.response &&
						err.response.data &&
						err.response.data.message) ||
					"Error occurred while updating add ons. Please try again!"
				);
			}
		},

		getPromoCodeDescription(code){
			if (code) {
				const foundPC = this.promoCodes.find(p => p.code == code)
				if (foundPC) return foundPC.description || ''
			}
			return ''
		}
    },
};
</script>

<style lang="scss" scoped>
.selected-activity {
	background-color: var(--primary);
	border-radius: 4px;
	color: #fff;
}
.activity {
	padding: 3px 7px;
	font-size: medium;
}
.card-grid {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    gap: 20px;
    @media screen and (min-width: 768px) {
        grid-template-columns: 1.5fr 1fr 1fr;
    }
}

.list-view {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: #e1dede 1px 1px 5px;
    border: none;
}
.gray-button {
    background-color: #fff;
    color: var(--gray);
    border: 1px solid var(--gray);
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
}

.ant-steps {
    width: 99%;
}

.ant-btn-group {
    > .ant-btn {
        border-radius: 4px !important;
    }
}
.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item-description {
    max-width: 175px;
}

.grid-display {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.75rem;
}
.type-card {
    border: 2px solid #d0c9d6;
    box-shadow: #e1dede 1px 1px 5px;
    border-radius: 4px;
    cursor: pointer;
}
.type-card:hover {
    border-color: var(--orange);
}

.selected-package {
    border: 2px solid var(--orange);
    border-radius: 4px;
}

.ant-radio-group-large .ant-radio-button-wrapper {
    line-height: 2;
}

.ant-tooltip .ant-tooltip-inner {
    background: #1b1c31;
}

.checkbox-label {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin: 0 0 0 25px;
}

.new-package-inputs {
    font-size: 16px;
    color: rgb(0, 0, 0);
    margin: 0px;
}

.feature-title {
    font-size: 18px;
    color: var(--orange);
    text-align: left;
    margin: 20px 0 0 0;
}

.ant-list-item {
    padding: 10px 0 0 0;
    border-bottom: 0px !important;
}

.modal-list {
    .modal-list-title {
        color: #40454c;
        font-size: clamp(16px, 2vw, 18px);
        line-height: clamp(20px, 2vw, 24px);
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
    }
    ul {
        list-style: none;
    }
}

.edit-delete {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    transition: color 0.1s ease-in;
}
</style>
